import React, { ChangeEvent } from 'react'
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material'
import FormContainer from './FormContainer'
import { useAppDispatch, useAppSelector } from '../../hooks'
import HeaderTypography from "./HeaderTypography"
import type { RootState } from "../../store"
import { AnyAction } from "redux"

interface RadioButtonProps<T> {
  name: string,
  text: string,
  data:  Array<{ key: T, val: string | JSX.Element }>,

  getState: (state: RootState) => T | null, // FIXME: Might be able to remove this function
  setAnswer: (answer: T) => AnyAction, 
}

function RadioButtonPage<T>(props: RadioButtonProps<T>) {
  const dispatch = useAppDispatch()
  const answer = useAppSelector(props.getState)
  const renderRadios = () => {
    return props.data.map((x, i) => {
      return <FormControlLabel key={"radio-" + props.name + i} value={x.key} control={<Radio />} label={x.val} />
    })
  }
  return <FormContainer willScroll>
          <HeaderTypography>{props.text}</HeaderTypography>
          <FormControl>
           <RadioGroup name={props.name}
                       value={answer}
                       onChange={(e: ChangeEvent<any>) => {dispatch(props.setAnswer(e.target.value))}}>
             { renderRadios() }
           </RadioGroup>
          </FormControl>
         </FormContainer>
}

export default RadioButtonPage
