import { Answer } from "../../../enums/utils"
import RadioButtonPage from "../../basic/RadioButtonPage"

import { setHasRebookNoFly } 
from "./RebookingSlice"

// export const NoRebookFlightRememberPage = () => {
//   const [answer, setAnswer] = useState<Answer | null>(null)
  
//   return <FormContainer willScroll>
//           <HeaderTypography>Har du mulighed for at give os flynummer og dato på den ombookning du blev tilbudt</HeaderTypography>
//           <FormControl>
//            <RadioGroup name={"radio-didflyrebook"}
//                        value={answer}
//                        onChange={(e: ChangeEvent<any>) => setAnswer(e.target.value)}>
//             <FormControlLabel key={"radio-norebookflight-1"} value={Answer.Yes} control={<Radio />} label={"Ja"} />
//             <FormControlLabel key={"radio-norebookflight-1"} value={Answer.No} control={<Radio />} label={"Nej"} />
//            </RadioGroup>
//           </FormControl>
//          </FormContainer>
// } 

export const HasRebookNoFlyPage = 
() => <RadioButtonPage 
        name="HasRebookNoFlyPage" 
        text="Har du mulighed for at give os flynummer og dato på den ombookning du blev tilbudt?"
        getState={(state) => state.rebooking.hasRebookNoFly}
        setAnswer={(x) => setHasRebookNoFly(x as Answer)}
        data={[
          { key: Answer.Yes, val: "Ja" }
        , { key: Answer.No, val: "Nej" }
        ]}
       />