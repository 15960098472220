import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Pax } from "../../../enums/entities/Pax"
import { setExpense } from '../ExtraExpensePage/ExtraExpenseSlice'

// Define a type for the slice state
interface ContactState {
    email: string | undefined,
    phoneNumber: string | undefined,
    passengers: Array<Partial<Pax>>
    pax: Array<Pax> // FIXME: Better name, it is the array of passengers that are being created
    expensePayoutPaxIndex: number,
    isValidated: boolean,
    shouldContinue: boolean,
}

// // Passenger Info
// export interface IPassengerInfo {
    // firstName?: string,
    // surName?: string,
    // address?: string,
    // city?: string,
    // postCode?: string,
    // cprNumber?: string,
    // country?: string,
    // overEighteen?: boolean,
    // guardianFirstName?: string,
    // guardianSurName?: string,
    // // signatureUrl?: string,
    // // acceptedTerms?: boolean,
// }

// Define the initial state using that type
const initialState: ContactState = {
    email: undefined,
    phoneNumber: undefined,
    // passengers: [new defaultPassenger]
    passengers: [{
        country: "Danmark",
        over18: true,
        // acceptedTerms: false,
    }],

    pax: [],

    expensePayoutPaxIndex: 0,

    isValidated: false,
    shouldContinue: false,
}


export const contactSlice = createSlice({
    name: 'contact',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        changeEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
        changePhoneNumber: (state, action: PayloadAction<string>) => {
            state.phoneNumber = action.payload
        },
        // initPassengersArray: (state, action: PayloadAction<number>) => {
            // state.passengers = Array<Partial<Pax>>(action.payload).fill({
                // country: "Danmark",
                // over18: true,
                // // acceptedTerms: false,
            // })
        // },
        // changeIthPassenger: (state, action: PayloadAction<{ pax: Partial<Pax>, i: number }>) => {
            // state.passengers[action.payload.i] = action.payload.pax
        // },
        // setFirstName: (state, action: PayloadAction<{ firstName: string, i: number }>) => {
            // state.passengers[action.payload.i].firstName = action.payload.firstName
        // },
        // setLastName: (state, action: PayloadAction<{ lastName: string, i: number }>) => {
            // state.passengers[action.payload.i].lastName = action.payload.lastName
        // },
        // setAddress: (state, action: PayloadAction<{ address: string, i: number }>) => {
            // state.passengers[action.payload.i].address = action.payload.address
        // },
        // setCity: (state, action: PayloadAction<{ city: string, i: number }>) => {
            // state.passengers[action.payload.i].city = action.payload.city
        // },
        // setPostCode: (state, action: PayloadAction<{ postCode: string, i: number }>) => {
            // state.passengers[action.payload.i].postCode = action.payload.postCode
        // },
        // setCountry: (state, action: PayloadAction<{ country: string, i: number }>) => {
            // state.passengers[action.payload.i].country = action.payload.country
        // },
        // setCprNumber: (state, action: PayloadAction<{ cprNumber: string, i: number }>) => {
            // state.passengers[action.payload.i].cprNumber = action.payload.cprNumber
        // },
        // changeIthPassenger: (state, action: PayloadAction<{pax: Partial<Pax>, i: number}>) => {
            // const oldVal: Partial<Pax> = state.passengers[action.payload.i]
            // const newVal: Partial<Pax> = action.payload.pax
            // const combined: Partial<Pax> = {...oldVal,...newVal}
            // state.passengers[action.payload.i] = combined;
        // },
        addPassenger: (state) => {
            state.passengers.push({
                country: "Danmark",
                over18: true,
                // acceptedTerms: false,
            })
        },
        removePassenger: (state) => {
            state.passengers.pop()
        },
        setPax: (state, action: PayloadAction<Array<Partial<Pax>>>) => {
            state.passengers = action.payload
        },
        setExpensePayoutPaxIndex: (state, action: PayloadAction<number>) => {
            state.expensePayoutPaxIndex = action.payload
        },
        setValidation: (state, action:PayloadAction<boolean>) => {
            state.isValidated = action.payload
        },
        setContinue: (state, action: PayloadAction<boolean>) => {
            state.shouldContinue = action.payload
            // state.pax = Object.assign(state.passengers) // FIXME: Very lazy way of converting the types, the pax should however already have been validated
        },

        clear: (state) => {
          state.email = undefined
          state.phoneNumber = undefined
          // passengers = [new defaultPassenger]
          state.passengers = [{
              country: "Danmark",
              over18: true,
              // acceptedTerms: false,
          }]

          state.pax = []

          state.isValidated = false
          state.shouldContinue = false
        }
    }
})

export const {
    changeEmail,
    changePhoneNumber,
    // changeIthPassenger,
    // setFirstName,
    // setLastName,
    // setAddress,
    // setCity,
    // setPostCode,
    // setCountry,
    // setCprNumber,
    setPax,
    addPassenger,
    removePassenger,
    setExpensePayoutPaxIndex,
    setValidation,
    setContinue,
    clear
} = contactSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default contactSlice.reducer
