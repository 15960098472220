import { createSlice, PayloadAction } from "@reduxjs/toolkit"

// Define a type for the slice state
interface AccountNumberState {
  accountNum: string
  regNum: string

  isValidated: boolean
  accountNumberContinue: boolean
}

// Define the initial state using that type
const initialState: AccountNumberState = {
  accountNum: "",
  regNum: "",

  isValidated: false,
  accountNumberContinue: false,
}

function validate(state: AccountNumberState): boolean {
  return state.accountNum !== "" && 
         state.regNum !== "" &&
         state.accountNum.length === 10 &&
         state.regNum.length === 4
}

export const AccountNumberSlice = createSlice({
    name: "accountNumber",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
      setAccountNum: (state, action: PayloadAction<string>) => { 
        state.accountNum = action.payload
        state.isValidated = validate(state)
      },
      setRegNum: (state, action: PayloadAction<string>) => { 
        state.regNum = action.payload
        state.isValidated = validate(state)
      },
      setContinue: (state, action: PayloadAction<boolean>) => {
        state.accountNumberContinue = true
      },
      clear: (state) => {
        state.accountNum = ""
        state.regNum = ""

        state.isValidated = false
        state.accountNumberContinue = false
      },
    }
})

export const { setAccountNum, setRegNum, setContinue, clear } = AccountNumberSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default AccountNumberSlice.reducer
