import { createSlice, PayloadAction } from "@reduxjs/toolkit"

// Define a type for the slice state
interface SignatureState {
    isSigned: boolean
}

// Define the initial state using that type
const initialState: SignatureState = {
    isSigned: false
}

export const SignatureSlice = createSlice({
    name: "signature",
    initialState,
        reducers: {
        setSigned: (state, action: PayloadAction<boolean>) => {
            state.isSigned = action.payload
        },
        clear: (state) => {
            return
        }
    }
})

export const { setSigned, clear } = SignatureSlice.actions

// Other code such as selectors can use the imported \`RootState\` type
// export const selectCount = (state: RootState) => state.counter.value

export default SignatureSlice.reducer
