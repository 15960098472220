import { TypedUseSelectorHook } from "react-redux"
import type { RootState } from "./store"

import { Airport } from "./utils"

// import CaseData from "./enums/CaseData"
import { Cases } from "./enums/entities/Cases"

function toDateOrNull(date: string | null): Date {
    // if (date === null) return null
    if (date === null) return new Date() // FIXME: Dirty fix, should honestly not take a Date | null type

    const dateFields = date.toString().split(" ")
    const dateString = [dateFields[0], dateFields[1], dateFields[2], dateFields[3]].join(" ") + " 00:00:00 GMT+0000"
    const dateFixed = new Date(dateString)

    return dateFixed
}

export const getCaseData = (selector: TypedUseSelectorHook<RootState>): Cases  => {
  return {
    caseId: selector((state: RootState) => Number(state.composition.caseId)),
    timestamp: new Date(), // FIXME: What should this be?

    wrongedDepAirport: { iata: selector((state: RootState) => state.chooseFlight.flight ? state.chooseFlight.flight.from.iata : "") },
    wrongedArrAirport: { iata: selector((state: RootState) => state.chooseFlight.flight ? state.chooseFlight.flight.to.iata   : "") },

    compensationInputChoice: selector((state: RootState) => state.compensationInput.choice),
    compensation: selector((state: RootState) => state.compensationInput.compensation),
    reimbursment: selector((state: RootState) => state.compensationInput.reimbursment),

    email: selector((state: RootState) => state.contact.email),
    phoneNumber: selector((state: RootState) => state.contact.phoneNumber),

    // FIXME: Change to use the pax instead later on
    // @ts-ignore
    passengers: selector((state: RootState) => state.contact.passengers),

    delayMessage: selector((state: RootState) => state.delayMessage.answer) || undefined,

    extraExpense: selector((state: RootState) => state.extraExpense.answer),
    expense: selector((state: RootState) => state.extraExpense.expense),
    // receipt: selector((state: RootState) => state.extraExpense.receipt),
    comment: selector((state: RootState) => state.extraExpense.comment),

    isPresent: selector((state: RootState) => state.isPresent.answer) || undefined,

    gotRefundTicket: selector((state: RootState) => state.noRebooking.gotRefundTicket) || undefined,

    newFlightAirline: { id: selector((state: RootState) => state.noRebooking.newFlightInfo.airline) },
    newFlightNumPrefix: selector((state: RootState) => state.noRebooking.newFlightInfo.flightNumPrefix),
    newFlightNum: selector((state: RootState) => state.noRebooking.newFlightInfo.flightNum),
    newFlightDate: toDateOrNull(selector((state: RootState) => state.noRebooking.newFlightInfo.date)),

    noRebook: selector((state: RootState) => state.noRebooking.noRebook) || undefined,

    refund: selector((state: RootState) => state.noRebooking.refund),

    didRebook: selector((state: RootState) => state.rebooking.didRebook) || undefined,
    didFlyRebook: selector((state: RootState) => state.rebooking.didFlyRebook) || undefined,
    hasRebook: selector((state: RootState) => state.rebooking.hasRebook) || undefined,

    endFlightAirline: { id: selector((state: RootState) => state.rebooking.endFlightInfo.airline) },
    endFlightNumPrefix: selector((state: RootState) => state.rebooking.endFlightInfo.flightNumPrefix),
    endFlightNum: selector((state: RootState) => state.rebooking.endFlightInfo.flightNum),
    endFlightDate: toDateOrNull(selector((state: RootState) => state.rebooking.endFlightInfo.date)),

    noDocsDate: toDateOrNull(selector((state: RootState) => state.rebooking.noDocsDate)),

    // @ts-ignore
    depAirport: selector((state: RootState) => state.route.depAirport.iata), // FIXME: Dirty fix
    // @ts-ignore
    arrAirport: selector((state: RootState) => state.route.arrAirport.iata), // FIXME: Dirty fix
    // @ts-ignore
    stopOverAirports: selector((state: RootState) => state.route.stopOverArray).map((a: Airport) => { return { iata: a.iata } }), // .map((a:Airport) => a.iata), // FIXME: Dirty fix
    specificFlightAirline: { id: selector((state: RootState) => state.specificFlight.airline) },
    specificFlightNumPrefix: selector((state: RootState) => state.specificFlight.flightNumPrefix),
    specificFlightNum: selector((state: RootState) => state.specificFlight.flightNum),
    specificFlightDate: toDateOrNull(selector((state: RootState) => state.specificFlight.date)),

    reason: selector((state: RootState) => state.reason.reason) || undefined,

    expensePayoutPaxIndex: selector((state: RootState) => state.contact.expensePayoutPaxIndex),

    regNum: selector((state: RootState) => state.accountNumber.regNum),
    accountNum: selector((state: RootState) => state.accountNumber.accountNum)

  }
}
