import React from 'react'
import AddStopOver from './AddStopOver'
import AskStopOver from './AskStopOver'
import RouteInput from './RouteInput'
import FormContainer from '../../basic/FormContainer'
import { useAppSelector, useAppDispatch } from '../../../hooks'
import {Button, Collapse} from '@mui/material'
import { setRouteInfoContinue } from "./RouteSlice"
import TagManager from 'react-gtm-module';

const RouteInfo = (props:{}) => {
    const airports = useAppSelector(state => state.route.stopOverArray)
    const { routeInfoContinue, isValidated } = useAppSelector(state => state.route)

    const dispatch = useAppDispatch()

    const handleRouteInfoContinue = () => {
        TagManager.dataLayer({
          dataLayer: {
            event: "route_info_continue",
          }
        })
        dispatch(setRouteInfoContinue(isValidated))
    }

    return <React.Fragment>
            <RouteInput />
            <AskStopOver/>
            <Collapse in={airports.length > 0}>
                <AddStopOver />
            </Collapse>
            <Collapse in={!routeInfoContinue && (isValidated)}>
              <FormContainer>
                <Button variant="contained"
                        onClick={handleRouteInfoContinue}>
                  Fortsæt
                </Button>
              </FormContainer>
            </Collapse>
           </React.Fragment>

}

export default RouteInfo
