import React, { useState, ChangeEvent, SyntheticEvent } from 'react'
import { AnyAction } from "redux"
import FormContainer from './FormContainer'
import { Button, TextField, Grid, Autocomplete, Collapse, Typography } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { da } from "date-fns/locale"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// import { setAirline, setFlightNumPrefix, setFlightNum, setDate, setSpecificFlightContinue } from './SpecificFlightSlice';
import { useAppDispatch } from '../../hooks'
import FlightIcon from '@mui/icons-material/Flight'
import HeaderTypography from "../basic/HeaderTypography"
import { searchAirlines } from "../../requests"
import { PrefixTextField, FlightNumTextField } from "./TextFields"

interface Slice {
  airline: string,
  flightNumPrefix: string, // The letters infront of the flight number (the sk in sk1234)
  flightNum: string, // The actual number of the flight number (the 1234 in sk1234)
  date: string | null, // NOTE: We might not be able to use date as an object

  isValidated: boolean,
  cont: boolean,

  time?: string | null
}

interface Reducers {
  setAirline: (airline: string) => AnyAction,
  setFlightNum: (flightNum: string) => AnyAction,
  setFlightNumPrefix: (flightNumPrefix: string) => AnyAction,
  setDate: (date: string | null) => AnyAction,
  setTime?: (date: string | null) => AnyAction,
  setContinue: (cont: boolean) => AnyAction,
}

interface InputAirlinePageProps {
  depAirport?: string,
  arrAirport?: string,

  text: string,
  slice: Slice,
  reducers: Reducers,
}

interface AutocompleteOption {
  name: string,
  iata: string,
  id: string,
}

const InputAirlinePage = (props: InputAirlinePageProps) => {
  const { flightNumPrefix, flightNum, date, isValidated, cont, time } = props.slice
  const [autocompOptions, setAutocompOptions] = useState<Array<AutocompleteOption>>([])
  const dispatch = useAppDispatch()

  const { setAirline, setFlightNumPrefix, setFlightNum, setDate, setContinue, setTime } = props.reducers

  const handleAirline = (e: object, value: AutocompleteOption | null) => { // FIXME: A type for the value that indicates it is an airline
    // We set the airline and if that airline also has an iata, then we set that as well.
    if (value !== null) {
      dispatch(setAirline(value.id))
      // @ts-ignore
      if (e.target.iata !== null) {
        dispatch(setFlightNumPrefix(value.iata))
      }
    }
    else {
      dispatch(setAirline(""))
      dispatch(setFlightNumPrefix(""))
    }
  }

  const handleAutocompReq = async (e: object) => {
    // @ts-ignore
    const data = await searchAirlines(e.target.value)
    if (data.ok)
        setAutocompOptions(data.val)
  }

  const handleFlightNumPrefix = (e: ChangeEvent<HTMLInputElement>) => {
    if (flightNumPrefix === "")
      dispatch(setFlightNumPrefix(e.target.value))
  }

  const handleFlightNum = (e: ChangeEvent<HTMLInputElement>) => {
    const flightNum: string = e.target.value.replace(/\D/, "")

    if (flightNum.length <= 4) dispatch(setFlightNum(flightNum))
  }

  const handleContinue = () => { dispatch(setContinue(isValidated)) }
  
  return <React.Fragment>
          <FormContainer willScroll>
            <HeaderTypography>{props.text}</HeaderTypography>
            { props.depAirport && props.arrAirport && 
            // <h4>{props.depAirport} <FlightIcon/> {props.arrAirport}</h4>
            <Typography variant="body1">{props.depAirport} <FlightIcon sx={{ transform: "rotate(90deg)" }}/> {props.arrAirport}</Typography>
            }
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Autocomplete 
                  options={autocompOptions}
                  noOptionsText={"Søg efter et flyselskab"}
                  data-testid="specificFlight-autocomplete"
                  fullWidth
                  // loading={true}
                  onChange={handleAirline}
                  onInputChange={handleAutocompReq}
                  getOptionLabel={(option) => `${option.name} (${option.iata})`}
                  renderInput={(params) => <TextField {...params}
                                              label="Flyselskab" 
                                              variant="outlined" 
                              />} 
                />
              </Grid>
              <Grid item md={4}>
                <Grid container spacing={0}>
                  <Grid item md={3} xs={3}>
                    <PrefixTextField 
                               fullWidth 
                               type="text" 
                               // disabled={flightNumPrefix !== ""} // FIXME: There is propably a smart way to do this
                               label="Fly prefix" 
                               variant="outlined"
                               value={flightNumPrefix}
                               onChange={handleFlightNumPrefix}
                               />
                  </Grid>
                  <Grid item md={9} xs={9}>
                    <FlightNumTextField 
                               fullWidth 
                               type="text" 
                               label="Fly nummer" 
                               variant="outlined"
                               value={flightNum}
                               onChange={handleFlightNum}
                               />
                  </Grid>
                </Grid>
              </Grid>
              { setTime !== undefined && time !== undefined &&
                <Grid item md={8} xs={12}>
                  <Grid container spacing={0}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid item md={6} xs={12}>
                        <MobileDatePicker 
                          label="Dato"
                          inputFormat="dd/MM/yy"
                          renderInput={(params) => <TextField fullWidth {...params} />}
                          disableFuture
                          value={date}
                          onChange={(date: Date | null) => {
                              if (date !== null) dispatch(setDate(date.toString()))
                              else dispatch(setDate(null))
                          }}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TimePicker
                          label="Ankomsttidspunkt"
                          value={time}
                          ampm={false}
                          renderInput={(params) => <TextField fullWidth {...params} />}
                          onChange={(date: Date | null) => {
                              if (date !== null) dispatch(setTime(date.toString()))
                              else dispatch(setTime(null))
                          }} />
                      </Grid>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              }
              { setTime === undefined && time === undefined &&
                <Grid item md={8} xs={12}>
                  <LocalizationProvider 
                    dateAdapter={AdapterDateFns}
                    locale={da}
                    localeText={{ cancelButtonLabel: "annuller", previousMonth: "Forrige måned", nextMonth: "Næste måned" }}
                   >
                    <MobileDatePicker 
                      label="Dato"
                      inputFormat="dd/MM/yy"
                      renderInput={(params) => <TextField fullWidth {...params} />}
                      disableFuture
                      value={date}
                      onChange={(date: Date | null) => {
                          if (date !== null) dispatch(setDate(date.toString()))
                          else dispatch(setDate(null))
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              }
            </Grid>
          </FormContainer>
          <Collapse in={!cont && 
                        isValidated}>
            <FormContainer>
              <Button variant="contained"
                      onClick={handleContinue}>
                Fortsæt
              </Button>
            </FormContainer>
          </Collapse>
         </React.Fragment>
  }

export default InputAirlinePage
