import React, { useState } from "react"

import { Stack,
         BottomNavigation,
         BottomNavigationAction,
         Divider,
         TextField,
         Button,
         Collapse 
} from "@mui/material"
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { da } from "date-fns/locale"
import SendEmailPage, { PageVariant } from "../../basic/SendEmailPage"
import EmailIcon from '@mui/icons-material/Email'
import AttachFileIcon from '@mui/icons-material/AttachFile'

import RadioButtonPage from "../../basic/RadioButtonPage"
import FormContainer from "./../../basic/FormContainer"
import InputAirlinePage from "./../../basic/InputAirlinePage"

import { setDidRebook, 
         setDidFlyRebook, 
         setHasRebook,
         setEndFlightInfoFlightNum,
         setEndFlightInfoFlightNumPrefix,
         setEndFlightInfoAirline,
         setEndFlightInfoDate,
         setEndFlightInfoContinue,
         setRebook,
         setRebookingFileValidated,
         setNoDocsDate,
         setNoDocsTime,
         setNoDocsContinue
       } 
from "./RebookingSlice"
import { Answer } from "../../../enums/utils"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import UploadFileComponent from "../../basic/UploadFileComponent";
import {sendUploadRequest} from "../../../requests";

import HeaderTypography from "../../basic/HeaderTypography"

import type { File } from "../../basic/UploadFileComponent"
import { StaticDatePicker } from "@mui/x-date-pickers"

export const DidRebookPage =
  () => <RadioButtonPage 
          name="DidRebookPage" 
          text="Tilbød flyselskabet ombooking af rejsen?"
          getState={(state) => state.rebooking.didRebook}
          setAnswer={(x) => setDidRebook(x as Answer)}
          data={[
            { key: Answer.Yes, val: "Ja" }
          , { key: Answer.No, val: "Nej" }
          ]}
         />

export const DidFlyRebookPage = 
() => <RadioButtonPage 
        name="DidFlyRebookPage"
        text="Endte du med at flyve med den ombookede afgang?"
        getState={(state) => state.rebooking.didFlyRebook}
        setAnswer={(x) => setDidFlyRebook(x as Answer)}
        data={[
          { key: Answer.Yes, val: "Ja" }
        , { key: Answer.No, val: "Nej" }
        ]}
       />

export const HasRebookPage = 
() => <RadioButtonPage 
        name="HasRebookPage" 
        text="Har du en bookingbekræftelse for ombookingen?"
        getState={(state) => state.rebooking.hasRebook}
        setAnswer={(x) => setHasRebook(x as Answer)}
        data={[
          { key: Answer.Yes, val: "Ja" }
        , { key: Answer.No, val: "Nej" }
        ]}
       />

export function NoDocsPage(props: {}) {
    const dispatch = useAppDispatch()

    const { noDocsDate, noDocsTime, noDocsIsValidated, noDocsContinue } = useAppSelector(state => state.rebooking)

    const handleContinue = () => { dispatch(setNoDocsContinue(noDocsIsValidated)) }

    return <React.Fragment>
             <FormContainer willScroll>
               <HeaderTypography>Hvornår ankom du ca. til den endelige ankomstlufthavn?</HeaderTypography>
               <Stack spacing={2}>
               <LocalizationProvider 
                 dateAdapter={AdapterDateFns}
                 locale={da}
                 localeText={{ cancelButtonLabel: "annuller", previousMonth: "Forrige måned", nextMonth: "Næste måned" }}
               >
                 <MobileDatePicker 
                   label="Dato"
                   inputFormat="dd/MM/yy"
                   renderInput={(params) => <TextField {...params} />}
                   disableFuture
                   value={noDocsDate}
                   onChange={(date: Date | null) => {
                       if (date !== null) dispatch(setNoDocsDate(date.toString()))
                       else dispatch(setNoDocsDate(null))
                   }}
                 />
                 <TimePicker
                   label="Ankomsttidspunkt"
                   value={noDocsTime}
                   ampm={false}
                   renderInput={(params) => <TextField {...params} />}
                   onChange={(date: Date | null) => {
                       if (date !== null) dispatch(setNoDocsTime(date.toString()))
                       else dispatch(setNoDocsTime(null))
                   }} />
               </LocalizationProvider>
               </Stack>
             </FormContainer>
            <Collapse in={!noDocsContinue && 
                          noDocsIsValidated}>
              <FormContainer>
                <Button variant="contained"
                        onClick={handleContinue}>
                  Fortsæt
                </Button>
              </FormContainer>
            </Collapse>
           </React.Fragment>
}

export const UploadRebookPage = (props: {}) => {
    const [activePage, setActivePage] = useState<PageVariant>(PageVariant.Email)
    const caseId = useAppSelector(state => state.composition.caseId)
    const isClicked = useAppSelector(state => state.rebooking.rebookingFileValidated)

    const dispatch = useAppDispatch()
    return <FormContainer willScroll>
             <Stack spacing={0}>
                <BottomNavigation
                    showLabels
                    value={activePage}
                    onChange={(event, value) => { 
                        setActivePage(value) 
                        dispatch(setRebookingFileValidated(false))
                    }}
                >
                    <BottomNavigationAction value={PageVariant.File} label="Upload fil" icon={<AttachFileIcon />}/>
                    <BottomNavigationAction value={PageVariant.Email} label="Send over email" icon={<EmailIcon />}/>
                </BottomNavigation>
                <Divider />
                { activePage === PageVariant.File ?
                    <UploadFilePage /> :
                    <SendEmailPage 
                      emailId={`${caseId}_ombooking`} 
                      onClick={() => dispatch(setRebookingFileValidated(!isClicked))} 
                      isClicked={isClicked} 
                      titleText="Send os bookingbekræftelsen for ombookingen"
                      step1Text="Find dokumentation, der viser ombookingen. Fx en e-mail fra dit flyselskab eller rejsebureau."
                    />
                }
             </Stack>
           </FormContainer>
}

const UploadFilePage = (props: {}) => {
    const dispatch = useAppDispatch()
    const caseId = useAppSelector(state => state.composition.caseId)
    const token = useAppSelector(state => state.composition.reqToken)


    const handleUpload = async (files:Array<File>) => {
        const res = await sendUploadRequest(files, "rebookreceipt", caseId, token)
        if (res.ok) {
            dispatch(setRebook({name: "MANGLER_AT_IMPLEMENTER_FIL", file: "A file"}))
            return res.ok
        } else {
            alert(res.val.message)
            return res.ok
        }
    }

    return <>
               <HeaderTypography>Upload bookingbekræftelse for ombooking</HeaderTypography>
               <UploadFileComponent handleUpload={handleUpload} />
           </>
}

export function EndFlightInfoPage(props: {}) {
    const { airline, flightNumPrefix, flightNum, date } = useAppSelector(state => state.rebooking.endFlightInfo)
    const { endFlightInfoContinue, endFlightInfoValidated } = useAppSelector(state => state.rebooking)
    const { arrAirport } = useAppSelector(state => state.route)
    const { didFlyRebook } = useAppSelector(state => state.rebooking)
    let title = `Oplys os om den afgang du ankom til destinationen (${arrAirport?.name}) med`
    if (didFlyRebook === Answer.No) {
        // title = `Oplys os om den afgang du ville have kommet til destinationen (${arrAirport?.name}), skulle du have taget i mod ombookingen?`
        title = `Oplys os om afgangen til den endelige destination (${arrAirport?.name}), som du blev tilbudt i forbindelse med ombookningen`
    }


    return <InputAirlinePage 
            text={title}
            slice={{
              airline,
              flightNumPrefix,
              flightNum,
              date,
              // time,
              cont: endFlightInfoContinue,
              isValidated: endFlightInfoValidated
            }}
            reducers={{
              setAirline: setEndFlightInfoAirline,
              setFlightNum: setEndFlightInfoFlightNum,
              setFlightNumPrefix: setEndFlightInfoFlightNumPrefix,
              setDate: setEndFlightInfoDate,
              // setTime: setEndFlightInfoTime,
              setContinue: setEndFlightInfoContinue,
            }}
           />
}
