import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Answer } from "../../../enums/utils"

// Define a type for the slice state
interface ExtraExpenseState {
  answer: Answer
  expense: string,
  receipt: string, // FIXME: Should be a file
  comment: string,

  isValidated: boolean
  extraExpenseContinue: boolean
}

// Define the initial state using that type
const initialState: ExtraExpenseState = {
    answer: Answer.No,
    expense: "",
    receipt: "",
    comment: "",

    isValidated: false,
    extraExpenseContinue: false,
}

function validate(state: ExtraExpenseState) {
  return state.answer === Answer.No ||
         (state.expense !== "" && state.receipt !== "")
}

export const extraExpenseSlice = createSlice({
    name: 'extraExpense',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAnswer: (state, action: PayloadAction<Answer>) => {
            state.answer = action.payload
            state.isValidated = validate(state)
        },
        setExpense: (state, action: PayloadAction<string>) => {
            state.expense = action.payload
            state.isValidated = validate(state)
        },
        setReceipt: (state, action: PayloadAction<string>) => {
            state.receipt = action.payload
            state.isValidated = validate(state)
        },
        setComment: (state, action: PayloadAction<string>) => {
            state.comment = action.payload
        },

        setContinue: (state, action: PayloadAction<boolean>) => {
            state.extraExpenseContinue = action.payload
        },
        clear: (state) => {
            state.answer = Answer.Yes
            state.expense = ""
            state.receipt = ""
            state.comment = ""

            state.isValidated = false
            state.extraExpenseContinue = false
        }
    }
})

export const { setAnswer, setExpense, setReceipt, setComment, setContinue, clear } = extraExpenseSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default extraExpenseSlice.reducer
