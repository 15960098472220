import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ReasonType from "../../../enums/ReasonType"

// Define a type for the slice state
interface ReasonState {
    reason: ReasonType | null,
}

// Define the initial state using that type
const initialState: ReasonState = {
    reason: null
}

export const reasonSlice = createSlice({
    name: 'reason',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setReason: (state, action:PayloadAction<ReasonType>) => {
            state.reason = action.payload
        },
    }
})

export const { setReason } = reasonSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default reasonSlice.reducer
