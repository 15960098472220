import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const enum Answer {
  Yes,
  No
}

// Define a type for the slice state
interface CancellationButInformedState {
    answer: Answer | null
}

// Define the initial state using that type
const initialState: CancellationButInformedState = {
    answer: null
}

export const cancellationButInformedSlice = createSlice({
    name: 'cancellationButInformed',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAnswer: (state, action: PayloadAction<Answer>) => {
            state.answer = action.payload
        },
        clear: (state) => {
            state = initialState
        }
    }
})

export const { setAnswer, clear } = cancellationButInformedSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default cancellationButInformedSlice.reducer
