import { configureStore } from '@reduxjs/toolkit'

import routeReducer from './components/flow/RouteInfo/RouteSlice'
import delayMessageReducer from './components/flow/RadioButtonPages/DelayMessageSlice'
import contactReducer from './components/flow/ContactPage/ContactSlice'
import isPresentReducer from './components/flow/RadioButtonPages/IsPresentSlice'
import cancellationButInformedReducer from './components/flow/RadioButtonPages/CancellationButInformedSlice'
import specificFlightReducer from './components/flow/SpecificFlightPage/SpecificFlightSlice'
import compositionReducer, { setPages } from './components/flow/CompositionPage/CompositionSlice'
import chooseFlightReducer from './components/flow/ChooseFlight/ChooseFlightSlice'
import reasonReducer from './components/flow/Reason/ReasonSlice'

import uploadBookingReducer from './components/flow/UploadBookingConfirmationPage/UploadBookingConfirmationSlice'

import rebookingReducer from "./components/flow/RebookingPage/RebookingSlice"
import noRebookingReducer from "./components/flow/NoRebookingPage/NoRebookingSlice"

import compensationInputReducer from './components/flow/CompensationInputPage/CompensationInputSlice'
import extraExpenseReducer from './components/flow/ExtraExpensePage/ExtraExpenseSlice'

import signatureReducer from './components/flow/SignaturePage/SignatureSlice'

import accountNumberReducer from "./components/flow/AccountNumberPage/AccountNumberSlice"

import getGraph from "./flowGraph"
// import type { Graph } from "./flowGraph" // FIXME: Necessary for the graph type

// FIXME: Is this necessary?
// const customizedMiddleware = getDefaultMiddleware({
  // serializableCheck: false
// })

const store = configureStore({
  reducer: {
    route:routeReducer,
    delayMessage: delayMessageReducer,
    contact: contactReducer,
    signature: signatureReducer,
    isPresent: isPresentReducer,
    cancellationButInformed: cancellationButInformedReducer,
    specificFlight: specificFlightReducer,
    composition: compositionReducer,
    reason: reasonReducer,

    uploadBooking: uploadBookingReducer,

    compensationInput: compensationInputReducer,
    extraExpense: extraExpenseReducer,

    rebooking: rebookingReducer,
    noRebooking: noRebookingReducer,

    chooseFlight: chooseFlightReducer,

    accountNumber: accountNumberReducer,
  }
})

// FIXME: It would be nice with some comments on what is happening here
function handleChange() {
  const state = store.getState()
  const graph = getGraph(state)

  if (state.composition.root === null) return
  let currentPage = state.composition.root
  
  const pages = [currentPage]
  // NOTE: This loop is going to run twice per insertion of a new element
  while (true) {
    if (graph[currentPage].nextPages.length === 0) break
    const nextPage: any = graph[currentPage].nextPages.find((x: any) => x.shouldContinue)
    if (nextPage === undefined) break
    currentPage = nextPage.name
    pages.push(currentPage)
  }
  const previousPage = pages[pages.length - 2]

  if (state.composition.pages[state.composition.pages.length-1] !== currentPage) {
    store.dispatch(setPages(pages))
    if (previousPage !== undefined)
      clearForward(store, graph, previousPage)
    else
      clearForward(store, graph, currentPage)
  }
}

// FIXME: Fix types for parameters
function clearForward(store: any, graph: any, curr: string) {
  if (graph[curr].nextPages.length === 0) 
    return
  else 
    graph[curr].nextPages.forEach((page: any) => {
      if (graph[page.name].clear !== null)
        store.dispatch(graph[page.name].clear())
      clearForward(store, graph, page.name)
    })
}

// const unsubscribe = store.subscribe(handleChange)
store.subscribe(handleChange)

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
