import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Answer } from "../../../enums/utils"

// Define a type for the slice state
interface DelayMsgState {
    answer: Answer | null
}

// Define the initial state using that type
const initialState: DelayMsgState = {
    answer: null
}

export const isPresentSlice = createSlice({
    name: 'isPresent',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAnswer: (state, action: PayloadAction<Answer|null>) => {
            state.answer = action.payload
        },
        clear: (state) => {
            state = initialState
        },
    }
})

export const { setAnswer, clear } = isPresentSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default isPresentSlice.reducer
