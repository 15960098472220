import React, { ChangeEvent } from 'react'
import { Pax } from "../../../enums/entities/Pax"
import { useAppSelector, useAppDispatch } from '../../../hooks'
import {FormControlLabel, FormGroup, Switch, Grid, Collapse} from '@mui/material'
import TextField from "@mui/material/TextField"
import {simpleValidateString, validateString, validateCpr} from "./validationFunctions"

function validate(value: string | undefined, checked: boolean):boolean{
    if (checked) return validateString(value)
    else return simpleValidateString(value)
}

const validateSpecial = (validated:boolean, checked:boolean): boolean => {
    if (checked) return validated
    else return true
}

interface OverEightToggleProps {
    pax: Partial<Pax>,
    checked: boolean,
    onSwitchChange: (e: ChangeEvent<HTMLInputElement>) => void,
    onGuardianFirstNameChange: (e: ChangeEvent<HTMLInputElement>) => void,
    onGuardianLastNameChange: (e: ChangeEvent<HTMLInputElement>) => void,
    onGuardianCprNumberChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export default function OverEightToggle(props: OverEightToggleProps) {
    const { pax } = props
    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                <FormGroup>
                    <FormControlLabel control={<Switch
                        checked={ pax.over18 }
                        onChange={props.onSwitchChange}
                    />} label="Over 18" />
                </FormGroup>
            </Grid>
            <Grid item md={6}>
                <Collapse in={!pax.over18}>
                    <TextField
                        value={pax.guardianFirstName}
                        onChange={props.onGuardianFirstNameChange}
                        label="Værge Fornavn"
                        variant="outlined"
                        error={!validate(pax.guardianFirstName, props.checked)}
                        fullWidth
                    />
                </Collapse>
            </Grid>
            <Grid item md={6}>
                <Collapse in={!pax.over18}>
                    <TextField
                        value={pax.guardianLastName}
                        onChange={props.onGuardianLastNameChange}
                        label="Værge Efternavn"
                        variant="outlined"
                        error={!validate(pax.guardianLastName, props.checked)}
                        fullWidth
                    />
                </Collapse>
            </Grid>
            <Grid item md={12}>
                <Collapse in={!pax.over18}>
                    <TextField
                        value={pax.guardianCprNumber}
                        onChange={props.onGuardianCprNumberChange}
                        label="Værge CPR"
                        variant="outlined"
                        error={!validateSpecial(validateCpr(pax.guardianCprNumber), props.checked)}
                        fullWidth
                    />
                </Collapse>
            </Grid>
        </Grid>
    )
}
