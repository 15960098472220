import { Divider, Typography } from "@mui/material"

const HeaderTypography = (props: { children: React.ReactNode }) => {
  return <div>
      <Typography sx={{color: "#3858e9", marginBottom: "20px"}} variant={"h6"}>{props.children}</Typography>
      {/* <Divider sx={{width:"60%", margin: "15px auto 15px auto"}} /> */}
    </div>
}

export default HeaderTypography
