import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import BlurredDialog from "../../basic/BlurredDialog"
import Typography from "@mui/material/Typography"
import { setEc261 } from "./SpecificFlightSlice";

function EuNoCompensationDialog(props:{}) {
    const eu_eligible = useAppSelector(state => state.specificFlight.eu_eligible)
    const dispatch = useAppDispatch()

    return (
        // 2 b) [kun boardingafvisning] Til stede ved gaten
        // Nej → du er ikke berettiget
        <BlurredDialog
            title={'Du er desværre ikke berettiget til kompensation'}
            open={eu_eligible !== null}
            handleClose={() => {dispatch(setEc261({eu_eligible:null, dk_eligible:null}))}}
        >
            <Typography>Da din flyvning ikke er i EU er du ikke berettiget til kompensation</Typography>
        </BlurredDialog>
)
}

export default EuNoCompensationDialog
