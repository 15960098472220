import React, { ChangeEvent } from 'react'
import { Typography, Link, Button, TextField, Stack, Icon, Grid } from "@mui/material"
import BlurredDialog from "./BlurredDialog"
import { sendMagicLink } from "../../requests"


const Header = (props:{}) =>  {
    const [openEmailDialog, setOpenEmailDialog] = React.useState<boolean>(false)
    const [isEmailSent, setIsEmailSent] = React.useState<boolean>(false)
    const [email, setEmail] = React.useState<string>("")

    const sendEmail = () => {
        if (email === "" || email === undefined || email == null) {
            alert("Indtast din email først")
            return
        }
        sendMagicLink(email)
        setIsEmailSent(true)
    }

    return <header>
        <div className="headerDiv">
            <Grid container spacing={1} justifyContent={"space-between"} maxWidth={150}>
                <Grid item xs={4}>
                    <img src="https://flysag.dk/wp-content/uploads/2024/05/Design-uden-navn-300x300.png" alt="FlySag" style={{width: 50, height: 50}}/>
                </Grid>
                <Grid item xs={8}>
                <Typography className="logo" variant='h5'>
                    <Link sx={{color: "#FFFFFF", fontWeight: "600"}} href="https://flysag.dk/" underline="none">
                    FlySag
                    </Link>
                </Typography>
                </Grid>
            </Grid>
             {/*<h1 className="logo">FlySag</h1>*/}
        {/*<Typography className={"logo"} variant={'h3'}>FlySag</Typography>*/}
        <input type="checkbox" id="nav-toggle" className="nav-toggle"></input>
             <nav>
               <ul>
                 <li><a href="https://flysag.dk/om-os/">Om os</a></li>
                 <li><a href="https://flysag.dk#rettigheder">Rettigheder</a></li>
                 <li><a href="https://flysag.dk/faq/">FAQ</a></li>
                 {/*<li className="dark-underline"><a href="#">Contact</a></li>*/}
                 <li><Button variant="outlined"
                             // sx={{ color: isPhone ? "#fff" : "#000", borderColor: isPhone ? "#fff" : "#000", textTransform:"none", fontSize:15}}
                            size={"small"}
                            //  sx={{ color: isPhone ? "#3858e9" : "#000", borderColor: isPhone ? "#3858e9" : "#000", textTransform:"none"}}
                             sx={{ color: "#FFFFFF", borderColor: "#FFFFFF", textTransform:"none", borderWidth:2}}
                             href='https://flysag.dk/#kontakt-footer'
                             >Kontakt os</Button></li>
               </ul>
             </nav>
             <label htmlFor="nav-toggle" className="nav-toggle-label">
               <span></span>
             </label>
            <BlurredDialog
                title={'Indtast din email'}
                open={openEmailDialog}
                handleClose={() => {
                    setOpenEmailDialog(false)
                    setEmail("")
                    setIsEmailSent(false)
                }}
                noOkButton={true}
            >
                {
                    !isEmailSent  &&
                    <Stack spacing={2}>
                        <Typography>Venligst indtast din email, så sender vi et link til dig</Typography>
                        <TextField
                            label={"Email"}
                            variant={"outlined"}
                            value={email}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setEmail(e.target.value)
                            }}
                        />
                        <Button variant={"contained"} onClick={sendEmail}>Send Email</Button>
                    </Stack>
                }
                {
                    isEmailSent &&
                    <Stack spacing={1} >
                        <Typography>Vi har sendt dig en email, tjek din indbakke</Typography>
                        <Typography variant={'body2'}>Kan du ikke se emailen vi har sendt så tjek din spam mappe eller
                        <Link onClick={() => {setIsEmailSent(false)}}>prøv igen</Link>.
                        </Typography>
                    </Stack>
                }
            </BlurredDialog>
        </div>
    </header>
}

export default Header
