import ButtonContainer from './ButtonContainer'
import {Container, Grid} from "@mui/material"


function Frontpage(props: {}) {
    return <> 
            <div style={{paddingTop: "230px", paddingBottom: "230px"}}>
              <Container >
                 <Grid container spacing={12} alignContent={"center"} justifyContent={"center"}>
                   <Grid item md={6} xs={12}>
                     <ButtonContainer />
                   </Grid>
                 </Grid>
              </Container>
            </div>
          </>
}

export default Frontpage
