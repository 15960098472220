import React from 'react'
// import Label from '../../basic/Label'
import { Chip, Grid, Typography } from '@mui/material'
import { removePassenger, addPassenger } from "./ContactSlice"
import { useAppSelector, useAppDispatch } from '../../../hooks'
import { Pax } from "../../../enums/entities/Pax"

function NumberOfPax(props:{ paxArr: Array<Partial<Pax>>, setPaxArr: (paxArr: Array<Partial<Pax>>) => void }) {
    // const passengersArray = useAppSelector(state => state.contact.passengers)
    const dispatch = useAppDispatch()
    return <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid item>
                    <Chip
                        label={<Typography variant="body1">-</Typography>}
                        variant={'outlined'}
                        clickable
                        disabled={props.paxArr.length < 2}
                        onClick={() => {
                            dispatch(removePassenger())
                            props.setPaxArr(props.paxArr.slice(0, -1))
                        }}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        // label={"Antal passagerer: " + passengersArray.length}
                        label={<Typography variant="body1">{"Antal passagerer: " + props.paxArr.length}</Typography>}
                    />
                </Grid>
                <Grid item>
                    <Chip
                        label={<Typography variant="body1">+</Typography>}
                        clickable
                        onClick={() => {
                            dispatch(addPassenger())
                            props.setPaxArr([...props.paxArr, { country: "Denmark", over18: true }])
                        }}
                    />
                </Grid>
           </Grid>
}

export default NumberOfPax
