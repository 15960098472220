import React from 'react'
import { useAppDispatch } from '../../../hooks'
import BlurredDialog from "../../basic/BlurredDialog"
import Typography from "@mui/material/Typography"
import { setAnswer } from "../RadioButtonPages/IsPresentSlice"

function IsPresentDialog(props:{}) {
    const dispatch = useAppDispatch()

    return (
        // 2 b) [kun boardingafvisning] Til stede ved gaten
        // Nej → du er ikke berettiget
        <BlurredDialog
            title={'Du er desværre ikke beretiget til kompensation'}
            open={true}
            // open={ isPresentAnswer == Answer.No
                // && reason == ReasonType.BoardingRejection}
            handleClose={() => {dispatch(setAnswer(null))}}
        >
            <Typography>Da du ikke var til stede ved gaten er du ikke beretiget til kompensation</Typography>
        </BlurredDialog>
)
}

export default IsPresentDialog
