import { Typography,  Tooltip } from "@mui/material"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React, { useState, useEffect } from "react"

export interface IStepCounterProps {
    num: number;
    end: number;
    moreThanOnePax?: boolean;
    onFinished?: () => void;
    isMobile?: boolean;
} 

export const StepCounter = (props: IStepCounterProps) => {
    const [ num, setNum ] = useState(props.num)
    const { end, onFinished } = props

    // Responsive design variables
    const margin = props.isMobile ? -10 : 0
    const headerStyle = props.isMobile ? "body1" : "h6"
    const iconSize = props.isMobile ? 14 : 18
    const iconStyle = props.isMobile ? "body2" : "body1"

    useEffect(() => {
      if (num === end) {
          if (onFinished !== undefined)
              onFinished()
          return
      }

      setTimeout(() => {
          if (num < end)
              setNum(num + 10) // FIXME: Does this overshoot
          if (num > end)
              setNum(num - 10) // FIXME: Does this overshoot
      }, 1)

    }, [num, end, onFinished])

    const textToDisplay = props.moreThanOnePax ? "Udbetaling: " : "Udbetaling per passager: "

    return <React.Fragment>
                { 
                num !== 0 && (<div style={{"marginBottom": margin}}>
                <p></p>
                <Typography variant={headerStyle}>{textToDisplay}<Typography variant={headerStyle} display={"inline"} sx={{color:"primary.main"}}>{num} kr </Typography> 
                    <Typography display={"inline"} variant={iconStyle} color={"#696969"}>
                        <Tooltip enterTouchDelay={0} title="Estimeret beløb udbetalt til din konto, når vores salær er fratrukket.">
                            <HelpOutlineIcon sx={{"fontSize": iconSize}}/>
                        </Tooltip>
                    </Typography>
                </Typography>
                </div>)
                }
            </React.Fragment>
    

}

