import validator from "validator"
import { Pax } from "../../../enums/entities/Pax"
// Validation for a string
// just checks if string is non-empty
// and not undefined and not null
export function validateString(value: string | undefined | null) {
    if (value === "" || value === undefined || value === null) return false;
    else return true
}
export function simpleValidateString(value: string | undefined) {
    if (value === "") return false;
    else return true
}

export function validatePhoneNumber(value: string | undefined) : boolean {
    if (value === undefined) return false
    return validator.isMobilePhone(value)
}

export function validateCpr(value: string | undefined) : boolean {
    // We need to force cpr to be inserted
    if (value === undefined) return false
    // Updated to allow optional CPR input
    // if (value === undefined || value === "") return true
    return (validator.isInt(value) && value.length === 10)
}


export function validateEmail(value: string | undefined) : boolean {
    if (value === undefined) return false
    return validator.isEmail(value)
}

// Validation for a single passenger
export function validatePax(pax: Partial<Pax>) :boolean { // FIXME: needs the pax from enum when it is in there
    if (!validateString(pax.firstName)
        || !validateString(pax.lastName)
        || !validateString(pax.address)
        || !validateString(pax.city)
        || !validateString(pax.postCode)
        || !validateString(pax.country)
        || !validateCpr(pax.cprNumber)
        || !validateString(pax.email)
    ) return false;
    if (!pax.over18) {
        if (!validateString(pax.guardianFirstName)
            || !validateString(pax.guardianLastName)
            || !validateCpr(pax.guardianCprNumber)){
            return false
        }
    }
    return true
}
