import React, { ChangeEvent, useState } from "react"

import { Stack,
         Button,
         TextField,
         Collapse,
         BottomNavigation,
         BottomNavigationAction,
         Divider
} from "@mui/material"
import EmailIcon from '@mui/icons-material/Email'
import AttachFileIcon from '@mui/icons-material/AttachFile'

import FormContainer from "./../../basic/FormContainer"
import RadioButtonPage from "../../basic/RadioButtonPage"
import InputAirlinePage from "./../../basic/InputAirlinePage"

import { setNoRebook, 
         setGotRefundTicket, 
         setNewFlightInfoFlightNum,
         setNewFlightInfoFlightNumPrefix,
         setNewFlightInfoAirline,
         setNewFlightInfoDate,
         setNewFlightInfoContinue,
         setRefund,
         setRefundContinue,
         setReceiptValidated,
         setOriginalFlightValidated
       } 
from "./NoRebookingSlice"
import NoRebookingChoice from "../../../enums/NoRebookingChoice"
import { Answer } from "../../../enums/utils"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import ReasonType from "../../../enums/ReasonType"
import UploadFileComponent from "../../basic/UploadFileComponent"
import SendEmailPage, { PageVariant } from "../../basic/SendEmailPage"
import { sendUploadRequest } from "../../../requests"

import HeaderTypography from "../../basic/HeaderTypography"

import type { File } from "../../basic/UploadFileComponent"

export const NoRebookPage = () => {
    const reason = useAppSelector(state => state.reason.reason)

    return <RadioButtonPage 
             name="NoRebookPage" 
             text="Hvad gjorde du så?"
             getState={(state) => state.noRebooking.noRebook}
             setAnswer={(x) => setNoRebook(x as NoRebookingChoice)}
             data={
             reason === ReasonType.Delayed ? 
               [ { key: NoRebookingChoice.OwnTicket,     val: "Jeg købte selv en ny billet" }
               , { key: NoRebookingChoice.OriginalFlight, val: "Jeg rejste med det oprindelige fly" }
               ]
               :
               [
                 { key: NoRebookingChoice.NeverTraveled, val: "Jeg kom aldrig ud at rejse" }
               , { key: NoRebookingChoice.OwnTicket,     val: "Jeg købte selv en ny billet" }
              //  , { key: NoRebookingChoice.OriginalFlight, val: "Jeg rejste med det oprindelige fly" }
               ]}
            />
}

export const GotRefundTicketPage = 
() => <RadioButtonPage 
        name="GotRefundTicketPage"
        text="Har du fået refunderet den oprindelige billetpris?"
        getState={(state) => state.noRebooking.gotRefundTicket}
        setAnswer={(x) => setGotRefundTicket(x as Answer)}
        data={[
          { key: Answer.Yes, val: "Ja" }
        , { key: Answer.No,  val: "Nej" }
        ]}
       />

export const UploadOriginalFlightPage = (props: {}) => {
    const [activePage, setActivePage] = useState<PageVariant>(PageVariant.Email)
    const caseId = useAppSelector(state => state.composition.caseId)
    const isClicked = useAppSelector(state => state.noRebooking.originalFlightValidated)

    const dispatch = useAppDispatch()
    return <FormContainer willScroll>
             <Stack spacing={0}>
                <BottomNavigation
                    showLabels
                    value={activePage}
                    onChange={(event, value) => { 
                        setActivePage(value) 
                        dispatch(setOriginalFlightValidated(false))
                    }}
                >
                    <BottomNavigationAction value={PageVariant.File} label="Upload fil" icon={<AttachFileIcon />}/>
                    <BottomNavigationAction value={PageVariant.Email} label="Send over email" icon={<EmailIcon />}/>
                </BottomNavigation>
                <Divider />
                { activePage === PageVariant.File ?
                    <UploadOriginalFlightFilePage /> :
                    <SendEmailPage 
                      emailId={`${caseId}_pris`} 
                      onClick={() => dispatch(setOriginalFlightValidated(!isClicked))} 
                      isClicked={isClicked} 
                      titleText="Send dokumentation for flyrejsens pris"
                      step1Text="Find dokumentation, der viser den betalte pris for flyvningen. Fx en e-mail fra dit flyselskab eller rejsebureau."
                    />
                }
             </Stack>
           </FormContainer>
}

const UploadOriginalFlightFilePage = (props: {}) => {
    const dispatch = useAppDispatch()

    const caseId = useAppSelector(state => state.composition.caseId)
    const token = useAppSelector(state => state.composition.reqToken)

    const handleUpload = async (files:Array<File>) => {
        const res = await sendUploadRequest(files,'originalflight', caseId, token)
        if (res.ok) {
            dispatch(setOriginalFlightValidated(true))
            return res.ok
        } else {
            alert(res.val.message)
            return res.ok
        }
    }


    return <FormContainer willScroll>
             <Stack spacing={0}>
               <HeaderTypography>Upload dokumentation for det betalte beløb for den oprindelige flyvning</HeaderTypography>
                 <UploadFileComponent handleUpload={handleUpload} />
             </Stack>
           </FormContainer>
}

export const UploadReceiptPage = (props: {}) => {
    const [activePage, setActivePage] = useState<PageVariant>(PageVariant.Email)
    const caseId = useAppSelector(state => state.composition.caseId)
    const isClicked = useAppSelector(state => state.noRebooking.receiptValidated)

    const dispatch = useAppDispatch()

    return <FormContainer willScroll>
             <Stack spacing={0}>
                <BottomNavigation
                    showLabels
                    value={activePage}
                    onChange={(event, value) => { 
                        setActivePage(value) 
                        dispatch(setReceiptValidated(false))
                    }}
                >
                    <BottomNavigationAction value={PageVariant.File} label="Upload fil" icon={<AttachFileIcon />}/>
                    <BottomNavigationAction value={PageVariant.Email} label="Send over email" icon={<EmailIcon />}/>
                </BottomNavigation>
                <Divider />
                { activePage === PageVariant.File ?
                    <UploadFileReceiptPage /> :
                    <SendEmailPage 
                      emailId={`${caseId}_kvittering`} 
                      onClick={() => dispatch(setReceiptValidated(!isClicked))} 
                      isClicked={isClicked} 
                      titleText="Send os dokumentation for den nye flyvnings pris"
                      step1Text="Find dokumentation, der viser den betalte pris for den nye flyvning. Fx en e-mail fra dit flyselskab eller rejsebureau."
                    />
                }
             </Stack>
           </FormContainer>
}

const UploadFileReceiptPage = (props: {}) => {
    const dispatch = useAppDispatch()
    const caseId = useAppSelector(state => state.composition.caseId)
    const token = useAppSelector(state => state.composition.reqToken)

    const handleUpload = async (files:Array<File>) => {
        const res = await sendUploadRequest(files,'receipt', caseId, token)
        if (res.ok) {
            dispatch(setReceiptValidated(true))
            return res.ok
        } else {
            alert(res.val.message)
            return res.ok
        }
    }

    return <FormContainer willScroll>
             <Stack spacing={0}>
               <HeaderTypography>Upload dokumentation for det betalte beløb for den nye flyvning</HeaderTypography>
               <UploadFileComponent handleUpload={handleUpload}/>
             </Stack>
           </FormContainer>
}

export function RefundInputPage(props: {}) {
    const dispatch = useAppDispatch()

    const { refund, refundValidated, refundContinue } = useAppSelector(state => state.noRebooking)

    const handleRefund = (e: ChangeEvent<HTMLInputElement>) => {
      const refund: string = e.target.value.replace(/\D/, "")
      dispatch(setRefund(refund))
    }

    const handleContinue = () => { dispatch(setRefundContinue(refundValidated)) }

    return <React.Fragment>
             <FormContainer willScroll>
               <HeaderTypography>Hvor meget var modtaget refusionsbeløb på?</HeaderTypography>
               <Stack spacing={2}>
                 <TextField fullWidth 
                            label="Refundering" 
                            variant="outlined" 
                            value={refund}
                            onChange={handleRefund}
                            />
               </Stack>
             </FormContainer>
             <Collapse in={!refundContinue && 
                            refundValidated}>
               <FormContainer>
                 <Button variant="contained"
                         onClick={handleContinue}>
                   Fortsæt
                 </Button>
               </FormContainer>
             </Collapse>
           </React.Fragment>
}

export function NewFlightInfoPage(props: {}) {
    const { airline, flightNumPrefix, flightNum, date } = useAppSelector(state => state.noRebooking.newFlightInfo)
    const { newFlightInfoContinue, newFlightInfoValidated } = useAppSelector(state => state.noRebooking)
    const arrAirport = useAppSelector(state => state.route.arrAirport)
    const title = `Oplys os om den afgang du ankom til destinationen (${arrAirport?.name}) med`

    return <InputAirlinePage 
            text={title}
            slice={{
              airline,
              flightNumPrefix,
              flightNum,
              date,
              // time,
              cont: newFlightInfoContinue,
              isValidated: newFlightInfoValidated
            }}
            reducers={{
              setAirline: setNewFlightInfoAirline,
              setFlightNum: setNewFlightInfoFlightNum,
              setFlightNumPrefix: setNewFlightInfoFlightNumPrefix,
              setDate: setNewFlightInfoDate,
              // setTime: setNewFlightInfoTime,
              setContinue: setNewFlightInfoContinue,
            }}
           />
  }
