import { styled } from "@mui/material/styles"
import { TextField } from '@mui/material'

export const PrefixTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        borderTopRightRadius: "0",
        borderBottomRightRadius: "0",
    },
})

export const FlightNumTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
    },
})
