import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline'
import store from './store'
import { Provider } from 'react-redux'
import RootPage from "./components/RootPage/RootPage"
import TagManager from 'react-gtm-module'

import { createTheme, ThemeProvider } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    primary: {
      // main: "#313E63"
      // main: "#000099"
      // main: "#000099"
      main:"#3858e9"
    }
  },
  typography: {
    fontFamily: [
      // 'Raleway',
      // 'Nunito',
      // 'Epilogue',
      'Inter',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
})

function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-WTXGPXG6' })
  })
  return (
    <Provider store={store}>
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <div className="App">
      <RootPage />
    </div>
    </ThemeProvider>
    </Provider>
  )
}

export default App;
