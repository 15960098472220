import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import BlurredDialog from "../../basic/BlurredDialog"
import Typography from "@mui/material/Typography"
import { setEc261 } from "./SpecificFlightSlice"

function DkNoCompensationDialog(props:{}) {
    const dk_eligible = useAppSelector(state => state.specificFlight.dk_eligible)
    const dispatch = useAppDispatch()

    return (
        // 2 b) [kun boardingafvisning] Til stede ved gaten
        // Nej → du er ikke berettiget
        <BlurredDialog
            title={'Vi kan desværre ikke tage din sag'}
            open={dk_eligible !== null}
            handleClose={() => {dispatch(setEc261({eu_eligible:null, dk_eligible:null}))}}
        >
            <Typography>Da sagen ikke kan køres i Danmark kan vi ikke tage din sag</Typography>
        </BlurredDialog>
)
}

export default DkNoCompensationDialog
