import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import CompensationInputChoice from "../../../enums/CompensationInputChoice"

// export const enum CompensationInputChoices {
  // No,
  // Compensation,
  // Reimbursment,
  // CompReim,
// }

// Define a type for the slice state
interface CompensationInputState {
    compensation: string
    reimbursment: string
    choice: CompensationInputChoice
    isValidated: boolean
    compensationInputContinue: boolean
}

// Define the initial state using that type
const initialState: CompensationInputState = {
    compensation: "",
    reimbursment: "",
    choice: CompensationInputChoice.No,
    isValidated: true,
    compensationInputContinue: false,
}

function validate(state: CompensationInputState): boolean {
  return (state.choice === CompensationInputChoice.Compensation && state.compensation !== "") ||
         (state.choice === CompensationInputChoice.Reimbursment && state.reimbursment !== "") ||
         (state.choice === CompensationInputChoice.CompReim && state.reimbursment !== "" && state.compensation !== "") ||
         (state.choice === CompensationInputChoice.No)
}

export const compensationInputSlice = createSlice({
    name: 'compensationInput',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setCompensation: (state, action: PayloadAction<string>) => {
            state.compensation = action.payload
            state.isValidated = validate(state)
        },
        setReimbursment: (state, action: PayloadAction<string>) => {
            state.reimbursment = action.payload
            state.isValidated = validate(state)
        },
        setContinue: (state, action: PayloadAction<boolean>) => {
          state.compensationInputContinue = action.payload
          state.isValidated = validate(state)
        },
        setChoice: (state, action: PayloadAction<CompensationInputChoice>) => {
          state.choice = action.payload

          state.compensation = ""
          state.reimbursment = ""
          state.isValidated = validate(state)
        },
        clear: (state) => {
          state.compensation = ""
          state.reimbursment = ""
          state.choice = CompensationInputChoice.No


          state.isValidated = true
          state.compensationInputContinue = false
        }
    }
})

export const { setCompensation, setReimbursment, setContinue, setChoice, clear } = compensationInputSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default compensationInputSlice.reducer
