import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { FlightInfo } from "../../../utils"
import { Answer } from "../../../enums/utils"
import NoRebookingChoice from "../../../enums/NoRebookingChoice"

interface FileInfo {
  name: string,
  file: string,
}

// Define a type for the slice state
interface NoRebookingState {
  noRebook: NoRebookingChoice | null

  gotRefundTicket: Answer | null

  receipt: FileInfo
  receiptValidated: boolean

  newFlightInfo: FlightInfo
  newFlightInfoValidated: boolean
  newFlightInfoContinue: boolean

  originalFlight: FileInfo
  originalFlightValidated: boolean

  refund: string
  refundValidated: boolean
  refundContinue: boolean
}

// FIXME: This function is used many places, refactor
function validateFlightInfo(info: FlightInfo): boolean {
  return info.airline !== "" &&
         info.flightNum !== "" &&
         info.flightNumPrefix !== "" &&
         info.date !== null
}

// FIXME: This function is used many places, refactor
function validateFileInfo(info: FileInfo): boolean {
  return info.name !== "" &&
         info.file !== ""
}

// Define the initial state using that type
const initialState: NoRebookingState = {
  noRebook: null,

  gotRefundTicket: null,

  receipt: {
    name: "",
    file: "",
  },
  receiptValidated: false,

  newFlightInfo: {
    airline: "",
    flightNumPrefix: "",
    flightNum: "",
    date: null,
  },
  newFlightInfoValidated: false,
  newFlightInfoContinue: false,

  originalFlight: {
    name: "",
    file: "",
  },
  originalFlightValidated: false,

  refund: "",
  refundValidated: false,
  refundContinue: false,
}

export const NoRebookingSlice = createSlice({
    name: "noRebooking",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
      setNoRebook: (state, action: PayloadAction<NoRebookingChoice>) => { 
        state.noRebook = action.payload
      },
      noRebookClear: (state) => {
        state.noRebook = null
      },

      setGotRefundTicket: (state, action: PayloadAction<Answer>) => { 
        state.gotRefundTicket = action.payload
      },
      gotRefundTicketClear: (state) => {
        state.gotRefundTicket = null
      },

      setOriginalFlight: (state, action: PayloadAction<FileInfo>) => {
        state.originalFlight = action.payload
        state.originalFlightValidated = validateFileInfo(action.payload)
      },
      setOriginalFlightValidated: (state, action: PayloadAction<boolean>) => {
        state.originalFlightValidated = action.payload
      },
      originalFlightClear: (state) => {
        state.originalFlight = {
          name: "",
          file: "",
        }
        state.originalFlightValidated = false
      },

      setReceipt: (state, action: PayloadAction<FileInfo>) => { 
        state.receipt = action.payload
        state.receiptValidated = validateFileInfo(action.payload)
      },
      setReceiptValidated: (state, action: PayloadAction<boolean>) => {
        state.receiptValidated = action.payload
      },

      receiptClear: (state) => {
        state.receipt = {
          name: "",
          file: "",
        }
        state.receiptValidated = false
      },

      setNewFlightInfoAirline: (state, action: PayloadAction<string>) => {
        state.newFlightInfo.airline = action.payload
        state.newFlightInfoValidated = validateFlightInfo(state.newFlightInfo)
      },
      setNewFlightInfoFlightNumPrefix: (state, action: PayloadAction<string>) => {
        state.newFlightInfo.flightNumPrefix = action.payload
        state.newFlightInfoValidated = validateFlightInfo(state.newFlightInfo)
      },
      setNewFlightInfoFlightNum: (state, action: PayloadAction<string>) => {
        state.newFlightInfo.flightNum = action.payload
        state.newFlightInfoValidated = validateFlightInfo(state.newFlightInfo)
      },
      setNewFlightInfoDate: (state, action: PayloadAction<string | null>) => {
        state.newFlightInfo.date = action.payload
        state.newFlightInfoValidated = validateFlightInfo(state.newFlightInfo)
      },
      setNewFlightInfoContinue: (state, action: PayloadAction<boolean>) => {
        state.newFlightInfoContinue = action.payload
      },
      newFlightInfoClear: (state) => {
        state.newFlightInfo = {
          airline: state.newFlightInfo.airline,
          flightNumPrefix: "",
          flightNum: "",
          date: null,
        }
        state.newFlightInfoValidated = false
        state.newFlightInfoContinue = false
      },


      setRefund: (state, action: PayloadAction<string>) => {
        state.refund = action.payload
        state.refundValidated = action.payload !== ""
      },
      setRefundContinue: (state, action: PayloadAction<boolean>) => {
        state.refundContinue = action.payload
      },
      refundClear: (state) => {
        state.refund = ""
        state.refundValidated = false
        state.refundContinue = false
      },
    }
})

export const { setNoRebook, 
               setGotRefundTicket, 
               setReceipt, 
               setNewFlightInfoAirline,
               setNewFlightInfoDate,
               setNewFlightInfoFlightNum,
               setNewFlightInfoFlightNumPrefix,
               setNewFlightInfoContinue,
               setOriginalFlight,
               setRefund,
               setRefundContinue,

               noRebookClear,
               gotRefundTicketClear,
               originalFlightClear,
               receiptClear,
               newFlightInfoClear,
               refundClear,
               setReceiptValidated,
               setOriginalFlightValidated
             } = NoRebookingSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default NoRebookingSlice.reducer
