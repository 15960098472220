import {
    Box,
    Button,
    Collapse,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
} from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete'
import UploadIcon from '@mui/icons-material/Upload'
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone'
import LoadingButton from '@mui/lab/LoadingButton'
import { TransitionGroup } from 'react-transition-group'
import React, { ChangeEvent } from "react"

enum UploadState {
    Initial,
    InProgress,
    Done
}

export interface File {
    name: string
}

// Barebones component just to demonstrate how to do this
function UploadFileComponent(props:{handleUpload: (files:Array<File>) => Promise<boolean>}) {
    const [files, setFiles] = React.useState<Array<File>>([])
    const [uploadState, setUploadState] = React.useState<UploadState>(UploadState.Initial)
    const addFiles = (newFiles: Array<File>) => {
        setFiles([...files, ...newFiles])
    }
    const removeFile = (index:number) => {
        setFiles((prev: Array<File>) => [...prev.filter((f:File, i:number) => i !== index)])
    }
    const handleFileChoose = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return
        addFiles(Array.from(e.target.files))
    }
    const handleUploadClick = async () => {
        if (files.length <= 0) {
            alert("Tilføj mindst en fil før du uploader")
            return
        }
        setUploadState(UploadState.InProgress)
        const res = await props.handleUpload(files)
        if (res) {
            setUploadState(UploadState.Done)
            setFiles([])
        }
        else setUploadState(UploadState.Initial)
    }
    const addFileForUploadButton = (
        <Button
            variant="outlined"
            disabled={uploadState !== UploadState.Initial}
            component="label"
        >
            + Tilføj fil
            <input
                type="file"
                hidden
                // accept=".pdf"
                accept=".pdf, .jpeg, .jpg, .png"
                onChange={handleFileChoose}
            />
        </Button>
    )
    const getUploadBtnText = () => {
        if (uploadState === UploadState.Done) return "Uploadet"
        else if (uploadState === UploadState.InProgress) return "Uploader..."
        else return "Upload filer"
    }
    const getUploadBtnIcon = () => {
        if (uploadState === UploadState.Done) return (<FileDownloadDoneIcon />)
        else return (<UploadIcon />)
    }
    const renderItem = (file: File, index:number) => {
        return (
            <ListItem
                secondaryAction={
                    <IconButton
                        edge="end"
                        aria-label="delete"
                        title="Delete"
                        disabled={uploadState !== UploadState.Initial}
                        onClick={() => {removeFile(index)}}
                    >
                        <DeleteIcon />
                    </IconButton>
                }
            >
                <ListItemText primary={file.name} />
            </ListItem>
        )
    }
    return (
        <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
            <Grid item md={12} xs={12}>
                <Box sx={{ mt: 1 }}>
                    <List>
                        <TransitionGroup>
                            {files.map((file: File, index:number) => (
                                <Collapse key={file.name + "_" + index.toString()}>
                                    {renderItem(file, index)}
                                </Collapse>
                            ))}
                        </TransitionGroup>
                    </List>
                </Box>
            </Grid>
            <Grid item md={12} xs={12}>
                <Grid spacing={1} container>
                    <Grid item md={6}>
                        {addFileForUploadButton}
                    </Grid>
                    <Grid item md={6}>
                        <LoadingButton
                            onClick={handleUploadClick}
                            loading={uploadState === UploadState.InProgress}
                            disabled={uploadState === UploadState.Done || files.length <= 0}
                            loadingPosition="start"
                            startIcon={getUploadBtnIcon()}
                            variant="contained"
                        >
                            {getUploadBtnText()}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UploadFileComponent
