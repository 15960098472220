import React, { ChangeEvent } from 'react';
import FormContainer from '../../basic/FormContainer';
import { useAppDispatch } from '../../../hooks'
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { initOrClearStopOvers } from './RouteSlice'
import HeaderTypography from "../../basic/HeaderTypography"

function convertStringToBool(x:string) {
    if (x ==="yes") return true;
    return false;
}

function AskStopOver(prop:{}) {
    const dispatch = useAppDispatch();
    return <FormContainer>
               <HeaderTypography>Var der mellemlandinger på rejsen?</HeaderTypography>
               <FormControl>
                  <RadioGroup
                    defaultValue="no"
                    row
                    onChange={(e: ChangeEvent<HTMLInputElement>) => { dispatch(initOrClearStopOvers(convertStringToBool(e.target.value))) }}
                    name="radio-buttons-group"
                  >
                      <FormControlLabel value="yes" control={<Radio />} label="Ja" />
                      <FormControlLabel value="no" control={<Radio />} label="Nej" />
                  </RadioGroup>
               </FormControl>
           </FormContainer>
}

export default AskStopOver;
