import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import BlurredDialog from "../../basic/BlurredDialog"
import Typography from "@mui/material/Typography"
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material"
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Link
} from "@mui/material"
import { LoadingButton } from "@mui/lab";
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import ReasonType from "../../../enums/ReasonType"
import { Answer } from "../../../enums/utils"
import DelayMsgAnswer from '../../../enums/DelayMsgAnswer'
import PersonDataDialog from "../../basic/PersonDataDialog"
import TermsDialog from "../../basic/TermsDialog"


function reasonText(reason: ReasonType | null): string {
  switch(reason) {
    case ReasonType.Cancelled: return "aflysningen"
    case ReasonType.Delayed: return "forsinkelsen"
    case ReasonType.BoardingRejection: return "boarding afvisningen"
    default: return "grunden" }
}




function FinalConfirmationDialog(props:{confirm: () => Promise<void>, isOpen: boolean, handleClose: () => void}) {

    const [loading, setLoading] = React.useState(false)
    const [checked, setChecked] = React.useState(false)
    const [correctChecked, setCorrectChecked] = React.useState(false)
    const [errChecked, setErrChecked] = React.useState(false)

    // const {didRebook} = useAppSelector(state => state.rebooking)
    // const {reason} = useAppSelector(state => state.reason)
    // const passengers = useAppSelector(state => state.contact.passengers)
    // const delayMessageAnswer = useAppSelector(state => state.delayMessage.answer)
    // const rebookedFlightNum = useAppSelector(state => state.rebooking.endFlightInfo.flightNum)
    // const rebookedFlightNumPrefix = useAppSelector(state => state.rebooking.endFlightInfo.flightNumPrefix)
    // const rebookedFlightDate = useAppSelector(state => state.rebooking.endFlightInfo.date)

    // const checkItems = []

    // const iwe_word = passengers.length > 1 ? "Vi " : "Jeg "

    // checkItems.push(iwe_word+"havde en bookingreservation til den indtastede flyrejse")

    // if (reason !== ReasonType.BoardingRejection) {
    //     if (delayMessageAnswer === DelayMsgAnswer.NoMessage)
    //         checkItems.push(iwe_word+"fik aldrig besked om " + reasonText(reason))

    //     else if (delayMessageAnswer === DelayMsgAnswer.LessThanSevenDays)
    //         checkItems.push(iwe_word+"fik besked om " + reasonText(reason) + " mindre end 7 dage før afgang")

    //     else if (delayMessageAnswer === DelayMsgAnswer.BetweenSevenAndFourteen)
    //         checkItems.push(iwe_word+"fik besked om " + reasonText(reason) + " mellem 7 og 14 dage før afgang")

    //     else if (delayMessageAnswer === DelayMsgAnswer.MoreThanFourteen)
    //         checkItems.push(iwe_word+"fik besked om " + reasonText(reason) + " mere end 14 dage før afgang")
    // }

    // else {
    //     checkItems.push(iwe_word+"var til stede ved gaten i tide til boarding af flyet")
    // }

    // if (reason === ReasonType.Delayed){
    //     checkItems.push(iwe_word+"var til stede ved gaten til boarding")
    //     checkItems.push(iwe_word+"ankom til destinationen efter at være fløjet med det forsinkede og/eller et ombooket fly")
    // }

    // if (didRebook === Answer.No)
    //     checkItems.push(iwe_word+"blev ikke tilbudt ombooking")
    // // Ask if "blev tilbudt ombooking til [flynummer] den DD/MM/YYYY" is correct
    // else
    //     checkItems.push(iwe_word+`blev tilbudt ombooking til ${rebookedFlightNumPrefix}${rebookedFlightNum} den ${rebookedFlightDate}`)

    return (
        // 2 b) [kun boardingafvisning] Til stede ved gaten
        // Nej → du er ikke berettiget
        <BlurredDialog
            // title={'Er alle oplysninger korrekte?'}
            title={'Før vi starter...'}
            open={props.isOpen}
            // open={ isPresentAnswer == Answer.No
                // && reason == ReasonType.BoardingRejection}
            handleClose={props.handleClose}
            noOkButton={true}
        >
        <Box display="flex" flexDirection="column" alignItems="center">
            {/* <Typography>Er følgende informationer korrekte?</Typography> */}
            {/* <List> */}
                {/* <ListItem>
                    <ListItemIcon><CheckIcon /></ListItemIcon>
                    <ListItemText primary="Jeg fik aldrig at vide mit fly var forsinket" />
                </ListItem>
                <ListItem>
                    <ListItemIcon><CheckIcon /></ListItemIcon>
                    <ListItemText primary="Jeg var tilstede ved gaten"/>
                </ListItem> */}
                {/* {
                    checkItems.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemIcon><CheckIcon /></ListItemIcon>
                            <ListItemText primary={item} />
                        </ListItem>
                    ))
                } */}
            {/* </List> */}
            {/* Button to confirm */}
                <FormControl error={false}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={correctChecked}
                            onChange={() => {setCorrectChecked(!correctChecked)}}
                        />}
                                          label={
                                              <Typography align="left">
                                                  {/* Jeg accepterer FlySags <PersonDataDialog variant={"body1"} /> og <TermsDialog variant={"body1"} />. */}
                                                  Alle oplysninger korrekte
                                              </Typography>
                                          }/>
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={checked}
                            onChange={() => {setChecked(!checked)}}
                        />}
                                          label={
                                              <Typography align="left">
                                                  {/* Jeg accepterer FlySags <PersonDataDialog variant={"body1"} /> og <TermsDialog variant={"body1"} />. */}
                                                  Jeg accepterer FlySags <Link href="https://flysag.dk/privacy-policy/" target="_blank" rel="noopener"> Persondatapolitik</Link>
                                              </Typography>
                                          }/>
                    </FormGroup>
                    {errChecked && <FormHelperText error={errChecked}>Bekræft og accepter for at gemme</FormHelperText>}
                </FormControl>
                <List></List>
            <LoadingButton loading={loading} disabled={loading} variant="contained"
                    onClick={async () => {
                        if (!checked || !correctChecked) {
                            setErrChecked(true)
                            return
                        }
                        setLoading(true)
                        await props.confirm()
                        setLoading(false)
                    }}>
                Start sag
            </LoadingButton>
        </Box>
        </BlurredDialog>
    )
}

export default FinalConfirmationDialog