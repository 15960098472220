import React, { ChangeEvent } from 'react'
import { Radio, RadioGroup, FormControl, FormControlLabel, Stack, Collapse, TextField, Button } from '@mui/material'
import FormContainer from './../../basic/FormContainer'
import { setCompensation, setReimbursment, setContinue, setChoice } from "./CompensationInputSlice"
import CompensationInputChoice from "../../../enums/CompensationInputChoice"
import { useAppSelector, useAppDispatch } from '../../../hooks'

import HeaderTypography from "../../basic/HeaderTypography"

interface CompensationInputPageProps {
  onlyCompensation?: boolean
}

function CompensationInputPage(props: CompensationInputPageProps) {
    // const [answer, setAnswer] = useState(Answer.Compensation)
    const { compensation, reimbursment, compensationInputContinue, isValidated, choice } = useAppSelector(state => state.compensationInput)

    const handleCompensation = (e: ChangeEvent<HTMLInputElement>) => {
      const compensation: string = e.target.value.replace(/\D/, "")
      dispatch(setCompensation(compensation))
    }

    const handleReimbursment = (e: ChangeEvent<HTMLInputElement>) => {
      const reimbursment: string = e.target.value.replace(/\D/, "")
      dispatch(setReimbursment(reimbursment))
    }

    const handleContinue = () => { dispatch(setContinue(isValidated)) }

    const dispatch = useAppDispatch()
    return <React.Fragment>
            <FormContainer willScroll>
              { props.onlyCompensation && <HeaderTypography>Har du allerede fået udbetalt kompensation fra flyselskabet?</HeaderTypography> }
              { !props.onlyCompensation && <HeaderTypography>Har du allerede fået udbetalt kompensation eller refusion fra flyselskabet?</HeaderTypography> }
              
              <Stack spacing={2}>
                <FormControl>
                 <RadioGroup
                     name="CompensationInputPage"
                     value={choice}
                     onChange={(e: ChangeEvent<HTMLInputElement>) => {dispatch(setChoice(e.target.value as CompensationInputChoice))}}
                 >
                   <FormControlLabel value={CompensationInputChoice.No} control={<Radio />} label="Nej" />
                   <FormControlLabel value={CompensationInputChoice.Compensation} control={<Radio />} label={props.onlyCompensation ? "Ja" : "Kun kompensation"} />
                   { !props.onlyCompensation && <FormControlLabel value={CompensationInputChoice.Reimbursment} control={<Radio />} label="Kun refusion" /> }
                   { !props.onlyCompensation && <FormControlLabel value={CompensationInputChoice.CompReim} control={<Radio />} label="Kompensation og refusion" /> }
                 </RadioGroup>
                </FormControl>
                <Collapse in={choice === CompensationInputChoice.Compensation || choice === CompensationInputChoice.CompReim}>
                  <TextField fullWidth 
                             label="Modtaget kompensationsbeløb" 
                             variant="outlined" 
                             value={compensation}
                             onChange={handleCompensation}
                             />
                </Collapse>
                <Collapse in={choice === CompensationInputChoice.Reimbursment || choice === CompensationInputChoice.CompReim}>
                  <TextField fullWidth 
                             label="Modtaget refusionsbeløb" 
                             variant="outlined" 
                             value={reimbursment}
                             onChange={handleReimbursment}
                             />
                </Collapse>
              </Stack>
             </FormContainer>
             <Collapse in={!compensationInputContinue && 
                           isValidated}>
               <FormContainer>
                 <Button variant="contained"
                         onClick={handleContinue}>
                   Fortsæt
                 </Button>
               </FormContainer>
             </Collapse>
           </React.Fragment> 
}

export default CompensationInputPage
