import React, { ChangeEvent } from 'react'
import { Radio, RadioGroup, FormControl, FormControlLabel, Stack, Collapse, TextField, Button, Divider } from '@mui/material'
import FormContainer from './../../basic/FormContainer'
import { setAnswer, setExpense, setReceipt, setComment, setContinue } from "./ExtraExpenseSlice"
import { useAppSelector, useAppDispatch } from '../../../hooks'
import { Answer } from "../../../enums/utils"
import InputAdornment from '@mui/material/InputAdornment'

import ReasonType from "../../../enums/ReasonType"
import UploadFileComponent, { File } from "../../basic/UploadFileComponent"
import { sendUploadRequest } from "../../../requests"

import HeaderTypography from "../../basic/HeaderTypography"

function reasonText(reason: ReasonType | null): string {
  switch(reason) {
    case ReasonType.Cancelled: return "aflysningen"
    case ReasonType.Delayed: return "forsinkelsen"
    case ReasonType.BoardingRejection: return "boarding afvisningen"
    default: return "grunden" }
}

function ExtraExpensePage(props: {}) {
    const { answer, expense, comment, isValidated, extraExpenseContinue } = useAppSelector(state => state.extraExpense)
    const { reason } = useAppSelector(state => state.reason)
    const caseId = useAppSelector(state => state.composition.caseId)
    const token = useAppSelector(state => state.composition.reqToken)

    const handleExpense = (e: ChangeEvent<HTMLInputElement>) => {
      const expense: string = e.target.value.replace(/\D/, "")
      dispatch(setExpense(expense))
    }

    const handleUpload = async (files: Array<File>) => {
        const res = await sendUploadRequest(files, "extrareceipt", caseId, token)
        if (res.ok) {
            dispatch(setReceipt("NOTHING_IS_HERE"))
            return res.ok
        } else {
            alert(res.val.message)
            return res.ok
        }
    }



    const handleContinue = () => { dispatch(setContinue(isValidated)) }

    const dispatch = useAppDispatch()
    return <React.Fragment>
             <FormContainer willScroll>
               <HeaderTypography>Medførte {reasonText(reason)} ekstraudgifter til overnatning, lufthavnstransport eller mad og drikke, som du har kvitteringer for?</HeaderTypography>
               <Stack spacing={2}>
                 <FormControl>
                  <RadioGroup
                      name="ExtraExpensePage"
                      value={answer}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {dispatch(setAnswer(e.target.value as Answer))}}
                  >
                    <FormControlLabel value={Answer.Yes} control={<Radio />} label="Ja" />
                    <FormControlLabel value={Answer.No} control={<Radio />} label="Nej" />
                  </RadioGroup>
                 </FormControl>
                 <Collapse in={answer === Answer.Yes}>
                   <Stack spacing={2}>
                     {/* <TextField fullWidth  */}
                     <TextField fullWidth
                                label="Totalomkostninger i DKK" 
                                variant="outlined" 
                                InputProps={{ 
                                  endAdornment: <InputAdornment position="end">DKK</InputAdornment>
                                }}
                                value={expense}
                                onChange={handleExpense}
                                />
                     <Divider />
                     <HeaderTypography>Upload kvittering(er) for ekstraudgifter</HeaderTypography>
                     <UploadFileComponent handleUpload={handleUpload} />
                     <Divider />
                     <HeaderTypography>Beskriv eventuelt kort, hvorfor det var nødvendigt at afholde ekstraomkostningerne</HeaderTypography>
                     <TextField fullWidth 
                                multiline
                                rows={4}
                                label="Skriv her" 
                                variant="outlined" 
                                value={comment}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {dispatch(setComment(e.target.value))}}
                                />
                   </Stack>
                 </Collapse>
               </Stack>
             </FormContainer>
             <Collapse in={!extraExpenseContinue && 
                           isValidated}>
               <FormContainer>
                 <Button variant="contained"
                         onClick={handleContinue}>
                   Fortsæt
                 </Button>
               </FormContainer>
             </Collapse>
           </React.Fragment> 
}

export default ExtraExpensePage
