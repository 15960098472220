import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ReasonType from "../../../enums/ReasonType"

// Define a type for the slice state
interface CompositionState {
    pages: Array<string>

    // For saving token and ids from creation response
    reqToken: string,
    caseId: string,
    paxIds: Array<string>,

    root: string | null

    // The number to be displayed on the step counter
    num: number
    // The number that the counter will count up to
    end: number
}

// Define the initial state using that type
const initialState: CompositionState = {
    pages: [],
    root: null,

    reqToken: "",
    caseId: "",
    paxIds: [],

    num: 0,
    end: 0,
}

function reasonToString(r: ReasonType): string {
  switch (r) {
    case ReasonType.Cancelled: return "delayMsg"
    case ReasonType.Delayed: return "delayMsg"
    case ReasonType.BoardingRejection: return "isPresent"
    default: return ""
  }
}

export const compositionSlice = createSlice({
    name: 'composition',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setRoot: (state, action: PayloadAction<ReasonType>) => {
          state.root = reasonToString(action.payload)
          // if (state.pages.length === 0)
            // state.pages.push(reasonToString(action.payload))
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.reqToken = action.payload
        },
        setCaseId: (state, action: PayloadAction<string>) => {
            state.caseId = action.payload
        },
        setPaxIds: (state, action: PayloadAction<Array<string>>) => {
            state.paxIds = action.payload
        },
        // addPage: (state, action: PayloadAction<string>) => {
          // state.pages.push(action.payload)
        // },
        setPages: (state, action: PayloadAction<Array<string>>) => {
          state.pages = action.payload

        },
        setEnd: (state, action: PayloadAction<number>) => {
            state.end = action.payload
        },
        setNum: (state, action: PayloadAction<number>) => {
            state.num = action.payload
        }
    }
})

export const { setRoot, setPages, setToken, setCaseId, setPaxIds, setNum, setEnd } = compositionSlice.actions


// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default compositionSlice.reducer
