import { createSlice, PayloadAction } from "@reduxjs/toolkit"

// Define a type for the slice state
interface UploadBookingConfirmationState {
    isValidated: boolean
}

// Define the initial state using that type
const initialState: UploadBookingConfirmationState = {
    isValidated: false
}

export const uploadBookingConfirmationSlice = createSlice({
    name: "uploadBooking",
    initialState,
    reducers: {
        setValidation: (state, action: PayloadAction<boolean>) => {
            state.isValidated = action.payload
        },
        clear: (state) => {
            state.isValidated = false
        }
    }
})

export const { setValidation, clear } = uploadBookingConfirmationSlice.actions

// Other code such as selectors can use the imported \`RootState\` type
// export const selectCount = (state: RootState) => state.counter.value

export default uploadBookingConfirmationSlice.reducer
