import { Typography } from '@mui/material';
import React, { Component } from 'react';

// interface IProps {
//     text: string;
// }

class Footer extends Component {
  render() {
    return <footer className="footer">
              <p>Copyright © 2024 FlySag</p>
           </footer>
  }
}

export default Footer;
