import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Airport } from "../../../utils"

// Define a type for the slice state
interface RouteState {
  depAirport: Airport | null,
  arrAirport: Airport | null,
  stopOverArray: Array<Airport | null>,

  isValidated: boolean,
  routeInfoContinue: boolean,
}

// Define the initial state using that type
const initialState: RouteState = {
  depAirport: null,
  arrAirport: null,
  stopOverArray: [],
  isValidated: false,
  routeInfoContinue: false,
}

function isValidated(state: RouteState) {
  return (state.depAirport !== null) 
      && (state.arrAirport !== null) 
      && (state.stopOverArray.every((x: Airport | null) => x !== null))
}

export const routeSlice = createSlice({
  name: 'route',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setDeparture: (state, action: PayloadAction<Airport | null>) => {
        state.depAirport = action.payload
        state.isValidated = isValidated(state)
        state.routeInfoContinue = false
    },
    setArrival: (state, action: PayloadAction<Airport | null>) => {
        state.arrAirport = action.payload
        state.isValidated = isValidated(state)
        state.routeInfoContinue = false
    },
    initOrClearStopOvers: (state, action:PayloadAction<boolean>) => {
        if(action.payload) state.stopOverArray = [null]
        else state.stopOverArray = []
        state.isValidated = isValidated(state)
        state.routeInfoContinue = false
    },
    setIthAirport: (state, action:PayloadAction<{val: Airport | null, i:number}>) => {
        state.stopOverArray[action.payload.i] = action.payload.val;
        state.isValidated = isValidated(state)
        state.routeInfoContinue = false
    },
    setIsValidated: (state, action: PayloadAction<boolean>) => {
        state.isValidated = action.payload
    },
    setRouteInfoContinue: (state, action: PayloadAction<boolean>) => {
        state.routeInfoContinue = action.payload
    },
    removeLastAirport: (state) => {
        state.stopOverArray.pop();
        state.isValidated = isValidated(state)
        state.routeInfoContinue = false
    },
    addAirport: (state) => {
        state.stopOverArray.push(null);
        state.isValidated = isValidated(state)
        state.routeInfoContinue = false
    },
    
    clear: (state) => {
        state.routeInfoContinue = false
    }
  }
})

export const { addAirport,
               removeLastAirport,
               setArrival,
               setDeparture,
               initOrClearStopOvers,
               setIthAirport,
               setIsValidated,
               setRouteInfoContinue,
               clear 
             } = routeSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default routeSlice.reducer
