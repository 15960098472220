import React, { ChangeEvent } from "react"
import { Grid, TextField, Collapse, Button } from "@mui/material"
import FormContainer from "./../../basic/FormContainer"
import { setAccountNum, setRegNum, setContinue } from "./AccountNumberSlice"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { saveAccountNumber } from "../../../requests"
import HeaderTypography from "../../basic/HeaderTypography"

const CLAIM_URL = process.env.REACT_APP_CLAIM_URL

function AccountNumberPage(props: {}) {
  const dispatch = useAppDispatch()

  const { accountNum, regNum, isValidated, accountNumberContinue } = useAppSelector(state => state.accountNumber)
  const caseId = useAppSelector(state => state.composition.caseId)
  const token = useAppSelector(state => state.composition.reqToken)

  const handleRegNum = (e: ChangeEvent<HTMLInputElement>) => {
    const regNum: string = e.target.value.replace(/\D/, "")
    if (regNum.length <= 4) dispatch(setRegNum(regNum))
  }
  const handleAccountNum = (e: ChangeEvent<HTMLInputElement>) => {
    const accountNum: string = e.target.value.replace(/\D/, "")
    if (accountNum.length <= 10) dispatch(setAccountNum(accountNum))
  }

  return <React.Fragment>
           <FormContainer willScroll>
             <HeaderTypography>Indtast den konto, som kompensationen skal udbetales til</HeaderTypography>
             <Grid container>
               <Grid item md={4} xs={8}>
                 <TextField fullWidth 
                            label="Reg-nr." 
                            variant="outlined" 
                            value={regNum}
                            onChange={handleRegNum}
                            helperText="4 cifre"
                            />
               </Grid>
               <Grid item md={7} xs={12}>
                 <TextField fullWidth 
                            label="Kontonummer" 
                            variant="outlined" 
                            value={accountNum}
                            onChange={handleAccountNum}
                            helperText="10 cifre"
                            />
               </Grid>
             </Grid>
           </FormContainer>
         </React.Fragment>
}

export default AccountNumberPage
