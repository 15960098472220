export type Result<T, E = Error> =
  | { ok: true; value: T }
  | { ok: false; error: E };

export const enum Answer {
  Yes,
  No,
}

export interface Airport {
  name: string,
  iata: string,
  ec261: boolean,
  countryCode: string
}

export interface FlightInfo {
  airline: string
  flightNumPrefix: string // The letters infront of the flight number (the sk in sk1234)
  flightNum: string // The actual number of the flight number (the 1234 in sk1234)
  date: string | null // NOTE: We might not be able to use date as an object
}

interface IAirport {
    ec261: boolean,
    country: string
}

interface Ec261ReturnType {
    eu_eligible: boolean,
    dk_eligible: boolean
}

export function checkCompensationEu261(depAirport: IAirport,
                                       arrAirport: IAirport,
                                       airline_eu261: boolean) : Ec261ReturnType {
    let eu_eligible = false;
    let dk_eligible = false;

    //////// EU Eligibility check
    // Hvis første lufthavn er indenfor EU
    //      = berettiget (ligegyldigt hvor endelig ankomstlufthavn ligger)
    if (depAirport.ec261) eu_eligible = true;
    // Hvis første lufthavn er udenfor EU = kun berettiget hvis
    //      endelig ankomstlufthavn er indenfor EU + flyselskabet er et EU-flyselskab
    else if (!depAirport.ec261 && arrAirport.ec261 && airline_eu261) eu_eligible = true;

    //////// DK Eligibility check
    // Enten første afgangslufthavn eller sidste ankomstlufthavn
    // skal være i DK for at vi kan køre sagen i DK
    if (depAirport.country === "DK" || arrAirport.country === "DK") dk_eligible = true;

    // Men Mike skal stadig vide, at hvis rejsen er startet udenfor EU,
    // er det ikke nok at den slutter i DK, fordi flyselskabet også skal være EU-flyselskab
    if (!eu_eligible) dk_eligible = false

    // return {eu_eligible, dk_eligible}
    // Removed eu check (always true)
    return {eu_eligible: true, dk_eligible}
}
