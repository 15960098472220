import { Typography,
         Avatar,
         List,
         ListItem,
         ListItemText,
         ListItemAvatar,
         ListItemButton,
         ListItemIcon,
         Checkbox,
} from "@mui/material"
import LooksOneIcon from '@mui/icons-material/LooksOne'
import LooksTwoIcon from '@mui/icons-material/LooksTwo'
import Looks3Icon from '@mui/icons-material/Looks3'
import HeaderTypography from "./HeaderTypography"
import { useMediaQuery } from "react-responsive"
import {CopyField} from '@eisberg-labs/mui-copy-field';
import React from "react"


export enum PageVariant {
    Email,
    File,
}

const SendEmailPage = (props: { 
    emailId: string, 
    onClick: () => void, isClicked: boolean,  
    titleText: string,
    step1Text: string,
}) => {
    const isDesktopOrLaptop = useMediaQuery({
      query: '(min-width: 1024px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const [copyBtnText, setCopyBtnText] = React.useState("Kopier")
    const handleCopy = () => {setCopyBtnText("Kopieret!")}
    const handleCopyError = () => {setCopyBtnText("Kopier fejlede!")}

    return <>
        {/* <HeaderTypography>Send bookingbekræftelse via email</HeaderTypography> */}
        <HeaderTypography>{props.titleText}</HeaderTypography>
        <List>
            <ListItem sx={isBigScreen || isDesktopOrLaptop ? { width: "50%", margin: "auto", marginBottom: "30px" } : {}}>
                <ListItemAvatar>
                    <Avatar>
                        <LooksOneIcon />
                    </Avatar>
                </ListItemAvatar>
                {/* <ListItemText primary={<Typography variant={"body1"}>Find den første e-mail, du modtog efter købet af rejsen, som indeholder din bookingbekræftelse og rejseplan.</Typography>} /> */}
                <ListItemText primary={<Typography variant={"body1"}>{props.step1Text}</Typography>} />
            </ListItem>
            <ListItem sx={isBigScreen || isDesktopOrLaptop ? { width: "50%", margin: "auto", marginBottom: "30px" } : {}}>
                <ListItemAvatar>
                    <Avatar>
                        <LooksTwoIcon />
                    </Avatar>
                </ListItemAvatar>
                { !(isBigScreen || isDesktopOrLaptop) && 
                    <ListItemText primary={
                        <div>
                            <Typography variant={"body1"}>Videresend til</Typography>
                            <CopyField value={`sag${props.emailId}@send.flysag.dk`} copyTooltip={copyBtnText} onCopySuccess={handleCopy} onCopyError={handleCopyError} fullWidth />
                        </div>
                    } />
                }
                { (isBigScreen || isDesktopOrLaptop) &&
                    <ListItemText primary={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant={"body1"} marginRight={1}>{`Videresend\u00A0til`}</Typography>
                            <CopyField value={`sag${props.emailId}@send.flysag.dk`} copyTooltip={copyBtnText} onCopySuccess={handleCopy} onCopyError={handleCopyError} fullWidth />
                        </div>
                    } />
                }
            </ListItem>
            <ListItem sx={isBigScreen || isDesktopOrLaptop ? { width: "50%", margin: "auto", marginBottom: "30px" } : {}}>
                <ListItemAvatar>
                    <Avatar>
                        <Looks3Icon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={<Typography variant={"body1"}>Bekræft videresendelsen nedenfor og fortsæt</Typography>} />
            </ListItem>
            <ListItem sx={isBigScreen || isDesktopOrLaptop ? { width: "50%", margin: "auto", marginBottom: "30px" } : {}}>
                <ListItemButton onClick={props.onClick}>
                    <ListItemIcon>
                        <Checkbox 
                            edge="start"
                            checked={props.isClicked}
                            disableRipple
                            inputProps={{ "aria-labelledby": "email-checkbox" }}
                        />
                    </ListItemIcon>
                    <ListItemText id={"email-checkbox"} primary={
                        <div>
                            <Typography>Jeg har videresendt mailen</Typography>
                            {/* <Typography>Jeg har videresendt bookingbekræftelsen</Typography> */}
                            {/* <Typography sx={{fontFamily: 'Courier New, monospace'}}>fs{props.emailId}@send.flysag.dk</Typography> */}
                        </div>
                    } />
                </ListItemButton>
            </ListItem>
        </List>
    </>
}

export default SendEmailPage
