import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Airport } from "../../../utils"

// Define a type for the slice state
interface ChooseFlightState {
    flight: {from: Airport, to: Airport} | null
    index: number | null
    allFlights: Array<{from: Airport, to: Airport}>
}

// Define the initial state using that type
const initialState: ChooseFlightState = {
    flight: null,
    index: null,
    allFlights: []
}

export const chooseFlightSlice = createSlice({
    name: 'chooseFlight',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setAllFlights: (state, action: PayloadAction<Array<{from: Airport, to: Airport}>>) => {
          state.allFlights = action.payload
        },
        setIndex: (state, action:PayloadAction<number>) => {
          state.index = action.payload
          state.flight = state.allFlights[state.index]
        },
        setFlight: (state, action: PayloadAction<{from: Airport, to: Airport}>) => {
          state.flight = action.payload
        },
        clear: (state) => {
            state.flight = null
            state.index = null
            state.allFlights = []
        },
    }
})

export const { setAllFlights, setIndex, setFlight, clear } = chooseFlightSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default chooseFlightSlice.reducer
