import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
interface SpecificFlightPageState {
  airline: string,
  flightNumPrefix: string, // The letters infront of the flight number (the sk in sk1234)
  flightNum: string, // The actual number of the flight number (the 1234 in sk1234)
  date: string | null, // NOTE: We might not be able to use date as an object

  eu_eligible: boolean | null,
  dk_eligible: boolean | null,

  isValidated: boolean,
  specificFlightContinue: boolean,
}

// Define the initial state using that type
const initialState: SpecificFlightPageState = {
  airline: "",
  flightNumPrefix: "",
  flightNum: "",
  date: null,

  eu_eligible: null,
  dk_eligible: null,

  isValidated: false,
  specificFlightContinue: false
}

function isValidated(state: SpecificFlightPageState) {
  return state.airline !== "" && 
         state.flightNumPrefix !== "" && 
         state.flightNum !== "" && 
         state.date !== null
}

export const specificFlightSlice = createSlice({
    name: 'specificFlight',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
      setAirline: (state, action: PayloadAction<string>) => {
        state.airline = action.payload
        state.isValidated = isValidated(state)
      },
      setFlightNumPrefix: (state, action: PayloadAction<string>) => {
        state.flightNumPrefix = action.payload
        state.isValidated = isValidated(state)
      },
      setFlightNum: (state, action: PayloadAction<string>) => {
        state.flightNum = action.payload
        state.isValidated = isValidated(state)
      },
      setEc261: (state, action: PayloadAction<{eu_eligible: boolean | null, dk_eligible: boolean | null}>) => {
        state.eu_eligible = action.payload.eu_eligible
        state.dk_eligible = action.payload.dk_eligible
      },
      // setDate: (state, action: PayloadAction<Date | null>) => {
      setDate: (state, action: PayloadAction<string | null>) => {
        state.date = action.payload
        state.isValidated = isValidated(state)
      },
      setSpecificFlightContinue: (state, action: PayloadAction<boolean>) => {
        state.specificFlightContinue = action.payload
      },
      clear: (state) => {
        // state.airline = ""
        state.flightNumPrefix = ""
        state.flightNum = ""
        state.date = null

        state.isValidated = false
        state.specificFlightContinue = false
        state.eu_eligible = null
        state.dk_eligible = null
      }
    }
})

export const { setAirline,
               setFlightNumPrefix,
               setFlightNum,
               setDate,
               setEc261,
               setSpecificFlightContinue,
               clear
             } = specificFlightSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default specificFlightSlice.reducer
