import React from "react"
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Footer from '../basic/Footer'
import Header from '../basic/Header'
import Frontpage from '../pages/Frontpage/Frontpage'
import CompositionPage from "../flow/CompositionPage/CompositionPage"
import PaxStatusPage from "../pages/PaxStatusPage"

import ReasonType from "../../enums/ReasonType"

function RootPage(props: {}) {
  return <React.Fragment>
           <BrowserRouter>
             {<Header />}
             <Routes>
               <Route path="/" element={<Frontpage />} />
               <Route path="/kompensation/forsinket" element={<CompositionPage reason={ReasonType.Delayed} />} />
               <Route path="/kompensation/aflyst" element={<CompositionPage reason={ReasonType.Cancelled} />} />
               <Route path="/kompensation/boarding-aflysning" element={<CompositionPage reason={ReasonType.BoardingRejection} />} />
               <Route path="/case-status" element={<PaxStatusPage />} />
             </Routes>
             <Footer />
           </BrowserRouter>
         </React.Fragment>
}

export default RootPage
