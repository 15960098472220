import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { FlightInfo } from "../../../utils"
import { Answer } from "../../../enums/utils"
import { setNoRebook } from "../NoRebookingPage/NoRebookingSlice"

export const enum RebookingChoices {
  HasRebook,
  NoRebook,
}

interface FileInfo {
  name: string,
  file: string,
}

// Define a type for the slice state
interface RebookingState {
  didRebook: Answer | null

  didFlyRebook: Answer | null

  hasRebook: Answer | null

  //
  hasRebookNoFly: Answer | null

  endFlightInfo: FlightInfo
  endFlightInfoContinue: boolean
  endFlightInfoValidated: boolean

  rebookingFile: FileInfo
  rebookingFileValidated: boolean

  noDocsDate: string | null
  noDocsTime: string | null
  noDocsContinue: boolean
  noDocsIsValidated: boolean
}

function validateEndFlightInfo(info: FlightInfo): boolean {
  return info.airline !== "" &&
         info.flightNum !== "" &&
         info.flightNumPrefix !== "" &&
         info.date !== null
}

function validateFileInfo(info: FileInfo): boolean {
  return info.name !== "" &&
         info.file !== ""
}

function validateNoDocs(state: RebookingState): boolean {
  return state.noDocsDate !== null && state.noDocsTime !== null
}

// Define the initial state using that type
const initialState: RebookingState = {
  didRebook: null,

  didFlyRebook: null,

  hasRebook: null,

  hasRebookNoFly: null,

  endFlightInfo: {
    airline: "",
    flightNumPrefix: "",
    flightNum: "",
    date: null,
  },
  endFlightInfoContinue: false,
  endFlightInfoValidated: false,

  rebookingFile: {
    name: "",
    file: "",
  },
  rebookingFileValidated: false,

  
  noDocsDate: null,
  noDocsTime: null,
  noDocsContinue: false,
  noDocsIsValidated: false,
}

export const RebookingSlice = createSlice({
    name: "rebooking",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
      setDidRebook: (state, action: PayloadAction<Answer|null>) => {
        state.didRebook = action.payload
      },
      didRebookClear: (state) => {
        state.didRebook = null
      },

      setDidFlyRebook: (state, action: PayloadAction<Answer|null>) => {
        state.didFlyRebook = action.payload
      },
      didFlyRebookClear: (state) => {
        state.didFlyRebook = null
      },

      setHasRebook: (state, action: PayloadAction<Answer|null>) => {
        state.hasRebook = action.payload
      },
      hasRebookClear: (state) => {
        state.hasRebook = null
      },

      setHasRebookNoFly: (state, action: PayloadAction<Answer|null>) => {
        state.hasRebookNoFly = action.payload
      },
      hasRebookNoFlyClear: (state) => {
        state.hasRebookNoFly = null
      },

      setEndFlightInfoAirline: (state, action: PayloadAction<string>) => {
        state.endFlightInfo.airline = action.payload
        state.endFlightInfoValidated = validateEndFlightInfo(state.endFlightInfo)
      },
      setEndFlightInfoFlightNumPrefix: (state, action: PayloadAction<string>) => {
        state.endFlightInfo.flightNumPrefix = action.payload
        state.endFlightInfoValidated = validateEndFlightInfo(state.endFlightInfo)
      },
      setEndFlightInfoFlightNum: (state, action: PayloadAction<string>) => {
        state.endFlightInfo.flightNum = action.payload
        state.endFlightInfoValidated = validateEndFlightInfo(state.endFlightInfo)
      },
      setEndFlightInfoDate: (state, action: PayloadAction<string | null>) => {
        state.endFlightInfo.date = action.payload
        state.endFlightInfoValidated = validateEndFlightInfo(state.endFlightInfo)
      },
      setEndFlightInfoContinue: (state, action: PayloadAction<boolean>) => {
        state.endFlightInfoContinue = action.payload
      },
      endFlightInfoClear: (state) => {
        state.endFlightInfo = {
                airline: state.endFlightInfo.airline,
                flightNumPrefix: "",
                flightNum: "",
                date: null,
              }
        state.endFlightInfoContinue = false
        state.endFlightInfoValidated = false
      },

      setRebook: (state, action: PayloadAction<FileInfo>) => {
        state.rebookingFile = action.payload
        state.rebookingFileValidated = validateFileInfo(action.payload)
      },
      setRebookingFileValidated: (state, action: PayloadAction<boolean>) => {
          state.rebookingFileValidated = action.payload
      },
      rebookClear: (state) => {
        state.rebookingFile = {
                name: "",
                file: "",
              }
        state.rebookingFileValidated = false
      },

      setNoDocsDate: (state, action: PayloadAction<string | null>) => {
        state.noDocsDate = action.payload
        state.noDocsIsValidated = validateNoDocs(state)
      },
      setNoDocsTime: (state, action: PayloadAction<string | null>) => {
        state.noDocsTime = action.payload
        state.noDocsIsValidated = validateNoDocs(state)
      },
      setNoDocsContinue: (state, action: PayloadAction<boolean>) => {
        state.noDocsContinue = action.payload
      },
      noDocsClear: (state) => {
        state.noDocsDate = null
        state.noDocsIsValidated = false
      }
    }
})

export const { setDidRebook, 
               setDidFlyRebook, 
               setHasRebook, 
               setEndFlightInfoDate,
               setEndFlightInfoFlightNum,
               setEndFlightInfoAirline, 
               setEndFlightInfoFlightNumPrefix,
               setEndFlightInfoContinue,
               setRebook,
               setNoDocsDate,
               setNoDocsTime,
               setNoDocsContinue,
               setRebookingFileValidated,

               setHasRebookNoFly,

               didRebookClear,
               didFlyRebookClear,
               hasRebookClear,
               endFlightInfoClear,
               rebookClear,
               noDocsClear,

               hasRebookNoFlyClear
             } = RebookingSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default RebookingSlice.reducer
