import React, { useState } from "react"
import { Stack,
         BottomNavigation,
         BottomNavigationAction,
         Divider,
         Typography,
         Avatar,
         List,
         ListItem,
         ListItemText,
         ListItemAvatar,
         ListItemButton,
         ListItemIcon,
         Checkbox,
         Grid 
} from "@mui/material"
import FormContainer from "./../../basic/FormContainer"
import { setValidation } from "./UploadBookingConfirmationSlice"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import UploadFileComponent from "../../basic/UploadFileComponent"
import SendEmailPage, { PageVariant } from "../../basic/SendEmailPage"
import { sendUploadRequest } from "../../../requests"
import EmailIcon from '@mui/icons-material/Email'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import LooksOneIcon from '@mui/icons-material/LooksOne'
import LooksTwoIcon from '@mui/icons-material/LooksTwo'
import Looks3Icon from '@mui/icons-material/Looks3'

import HeaderTypography from "../../basic/HeaderTypography"

const UploadBookingConfirmationPage = (props: {}) => {
    const [activePage, setActivePage] = useState<PageVariant>(PageVariant.Email)
    const caseId: string = useAppSelector(state => state.composition.caseId)
    const isClicked = useAppSelector(state => state.uploadBooking.isValidated)

    const dispatch = useAppDispatch()

    return <FormContainer willScroll>
        <Stack spacing={2}>
            <BottomNavigation
                showLabels
                value={activePage}
                onChange={(event, value) => { 
                    setActivePage(value) 
                    dispatch(setValidation(false))
                }}
            >
                <BottomNavigationAction value={PageVariant.File} label="Upload fil" icon={<AttachFileIcon />}/>
                <BottomNavigationAction value={PageVariant.Email} label="Send over email" icon={<EmailIcon />}/>
            </BottomNavigation>
            <Divider />
            { activePage === PageVariant.File ?
                <UploadFilePage /> :
                <SendEmailPage 
                    emailId={`${caseId}_booking`} 
                    onClick={() => dispatch(setValidation(!isClicked))} 
                    isClicked={isClicked}
                    titleText="Send bookingbekræftelse via email"
                    step1Text="Find den første e-mail, du modtog efter købet af rejsen, som indeholder din bookingbekræftelse og rejseplan."
                />
            }
        </Stack>
    </FormContainer>
}


const UploadFilePage = (props: {}) => {
    const caseId = useAppSelector(state => state.composition.caseId)
    const token = useAppSelector(state => state.composition.reqToken)

    const dispatch = useAppDispatch()

    const handleUpload = async (files:Array<any>) => {
        const res = await sendUploadRequest(files, "bookingconfirmation", caseId, token)
        if (res.ok) {
            dispatch(setValidation(true))
            return res.ok
        } else {
            alert(res.val.message)
            return res.ok
        }
    }

    return <>
        <HeaderTypography>Upload bookingbekræftelse</HeaderTypography>
        <UploadFileComponent handleUpload={handleUpload} />
    </>
}

export default UploadBookingConfirmationPage
