import React, { useEffect, useState, ChangeEvent } from 'react'
import FormContainer from '../../basic/FormContainer'
import ContactInfo from './ContactInfo'
import NumberOfPax from './NumberOfPax'
import { Grid, Accordion, AccordionDetails, AccordionSummary, Collapse } from '@mui/material'
import { useAppSelector, useAppDispatch } from '../../../hooks'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import { setValidation, setContinue, changeEmail, changePhoneNumber } from "./ContactSlice"
import { setPaxIds } from "../CompositionPage/CompositionSlice"
import { validatePax, validatePhoneNumber, validateEmail } from "./validationFunctions"
import { saveCaseData } from '../../../requests'
import ErrorIcon from '@mui/icons-material/Error'
import {getCaseData} from "../../../caseDataType"
import { LoadingButton } from "@mui/lab"
import { Pax } from "../../../enums/entities/Pax"
import { setPax } from "./ContactSlice"
import { Answer } from '../../../enums/utils'
import FinalConfirmationDialog from "./FinalConfirmationDialog"

type PaxFields = "firstName" | "lastName" | "address" | "city" | "postCode" | "country" | "cprNumber" | "email" | "phoneNumber"

const CLAIM_URL = process.env.REACT_APP_CLAIM_URL

const ContactPage = (props: {}) => {
    const {email, phoneNumber, isValidated} = useAppSelector(state => state.contact)
    const [paxArr, setPaxArr] = useState<Array<Partial<Pax>>>([{ country: "Denmark", over18: true }])
    // Need to define local state for email and phone number, since dispatch
    // will make will dehook and hook making it hard to use on mobile
    const [localEmail, setLocalEmail] = useState<string>('')
    const [localPhoneNumber, setLocalPhoneNumber] = useState<string>('')
    const [paxIsDispatched, setPaxIsDispatched] = useState<boolean>(false)
    const [expanded, setExpanded] = useState<string | false>('panel-pax-0')
    const [checked, setChecked] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch()
    // Data for request

    const caseId = useAppSelector(state => state.composition.caseId)
    const token = useAppSelector(state => state.composition.reqToken)

    const caseData = getCaseData(useAppSelector)

    useEffect(() => {
        if (paxIsDispatched) {
            createCaseSaveData(caseData)
        }
    }, [paxIsDispatched])

    // Save data request
    const createCaseSaveData = async (caseData: any) => {
        setLoading(true)
        const data = {
            caseId,
            ...caseData,
        }
        // dispatch(setPaxIds(res.val))
        setLoading(false)
        dispatch(setValidation(true))
    }

    // Validate all fields
    const validate = (): boolean => {
        // Set flag
        setChecked(true)
        // Check email and phone
        // if (!validateEmail(localEmail) || !validatePhoneNumber(localPhoneNumber)){
        //     return false
        // }
        // Check passenger array
        for (let pax of paxArr) {
            if (!validatePax(pax)) {
                return false
            }
        }
        return true
    }

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false)
        }

    // This component does not have a isScroll because the accordion handles that
    return <React.Fragment>
                <FormContainer willScroll>
                    <Grid container spacing={3} alignItems="center" justifyContent="center">
                        <Grid item md={12}>
                            <NumberOfPax paxArr={paxArr} setPaxArr={setPaxArr} />
                        </Grid>
                        <Grid item md={12}>
                    {
                        paxArr.map((pax: Partial<Pax>, i: number) => { // FIXME: Needs pax type
                            return <Accordion key={'panel-pax-key-'+i} 
                                              expanded={expanded === 'panel-pax-'+i} 
                                              onChange={handleChange('panel-pax-'+i)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    // aria-controls="panel1bh-content"
                                    aria-controls={'panel-pax-'+i+'bh-content'}
                                    id={'panel-pax-'+i+'bh-header'}
                                >
                                    {
                                        (checked && (!validatePax(pax))) &&
                                        <ErrorIcon color="error"/>
                                    }
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        Passager {i+1}
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>{pax.firstName} {pax.lastName}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        (expanded === 'panel-pax-'+i) &&
                                        <ContactInfo 
                                            i={i} 
                                            pax={pax}
                                            checked={checked}
                                            onChange={(item: PaxFields) => (e: ChangeEvent<HTMLInputElement>) => {
                                                const newArr = [...paxArr]
                                                newArr[i][item] = e.target.value
                                                setPaxArr(newArr)
                                            }}
                                            onSwitchChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                const newArr = [...paxArr]
                                                newArr[i].over18 = e.target.checked
                                                setPaxArr(newArr)
                                            }}
                                            email={localEmail}
                                            setEmail={setLocalEmail}
                                            phoneNumber={localPhoneNumber}
                                            setPhoneNumber={setLocalPhoneNumber}
                                        />
                                    }
                                </AccordionDetails>
                            </Accordion>
                        })
                    }
                        </Grid>
                    </Grid>
                    </FormContainer>
                    <FormContainer>
                        <LoadingButton loading={loading} onClick={async () => {
                            if (validate()) {
                                dispatch(changeEmail(localEmail))
                                dispatch(changePhoneNumber(localPhoneNumber))
                                dispatch(setPax(paxArr))
                                // dispatch(setContinue(true))
                                setPaxIsDispatched(true)

                            }
                            else dispatch(setValidation(false))
                        }} variant="contained" size="large">Fortsæt</LoadingButton>
                    </FormContainer>
           </React.Fragment>
}

export default ContactPage
